<template>
 
 <div class="modal fade" id="RegisterUserOPTModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>
            <div class="modal-body p-4">
              <h6 class="mb-4">Quick Register</h6>

             
              <div class="mb-3">
                <label for="userSignInEmail" class="form-label fs-sm fw-bold">New Password(5 Digits)</label>
               <div class="input-group">
              <span class="input-group-text"><i class="fas fa-lock"></i></span>
              <input class="form-control" @keypress="isNumber($event)" type="password" id="passwordregisteruser" name="password" minlength="5" maxlength="5" required v-model="Password" placeholder="Enter New Password" >
              <span class="input-group-text">
                <i class="far fa-eye" id="togglePassword" 
              style="cursor: pointer"></i>
              </span>
            </div>
          </div>


              <div class="mb-3">
                <label for="userSignInEmail" class="form-label fs-sm fw-bold">Mobile Number</label>
                <input type="number" :disabled="VerifyOTP" v-model="Mobile" required minlength="10" class="form-control shadow-none" placeholder="Enter Mobile Number" id="userSignInEmail" />
              </div>

               <div class="mb-3" v-if="VerifyOTP">
                <label for="userSignInPassword" class="form-label fs-sm fw-bold">Enter OTP Number</label>
               <div class="input-group mb-3" >
              <button class="btn btn-outline-secondary" type="button" id="button-addon1"
              :disabled="!CanSubmitBeforeOTP || $wait.is('LoggingIn')"
              @click="VerifyOTPClick"
              ><v-wait for="LoggingIn">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                     Resend
                  </v-wait>
                  </button>
              <input type="number" :class="MATCHED ? 'alert-success' : 'alert-danger'" ref="OTP" minlength="4" maxlength="4" v-model="OTP" class="form-control shadow-none" placeholder="Enter OTP Number" id="userSignInPassword" />
              </div>
              </div>
              <div class="mb-3" v-if="VerifyOTP">
                <button type="submit" @click="RegisterCommuter" class="btn btn-primary w-100 text-uppercase fw-bold"
                :disabled="!CanSubmit || $wait.is('LoggingIn')"
                >
                 <v-wait for="LoggingIn">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                     Submit
                  </v-wait>
                </button>
              </div>

              <div class="mb-3" v-if="!VerifyOTP">
                <button type="submit" @click="VerifyOTPClick" class="btn btn-primary w-100 text-uppercase fw-bold"
                :disabled="!CanSubmitBeforeOTP || $wait.is('LoggingIn')"
                >
                 <v-wait for="LoggingIn">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                     Send OTP
                  </v-wait>
                </button>
              </div>
          </div>
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- modal-dialog modal-dialog-centered -->
    </div>
    <!-- /.modal -->
  
</template>

<script>


import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('auth');

export default {
	 name: "RegisterUserOPTModal",
	 props:[''],
  data: function() {
    return {
      FirstName: '',
      LastName: '',
      Mobile: '',
      OTP: '',
      Password: '',
      uid:this.$store.getters["auth/UID"],
      VerifyOTP: false,
      FULLNAME: this.$store.getters["auth/FULLNAME"],
      MatchOTP: '',
    };
  },
  computed: {
    ...mapGetters(["USERPROFILE","SELECTEDTAXIASSOCIATION"]),
    CanSubmit: function (){
      return this.Mobile.length != 0 && this.OTP.length === 4 && this.Password != "" && this.Password.length == 5 && this.MatchOTP === this.OTP;
    },
    CanSubmitBeforeOTP: function (){
      return this.Mobile.length != 0 && this.Password != "" && this.Password.length == 5
    },
     MATCHED: function (){
      return this.OTP.length === 4 && this.MatchOTP === this.OTP;
    },
  },
  components: {
  },
  methods: {
    ...mapActions([
      "RegisterCommuterAction",
      "RequestOTPAction",
      "GetProfile",
    ]),
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    VerifyOTPClick: function ()
    {
      
        this.$Notify
        .Confirm("OTP Will Be Sent You","",false,true)
        .then((value) => {
          if (value) {
             //this.$refs.OTP.focus();

             var context = $(window).data("RegisterUserOPTModal");

              context.$wait.start("LoggingIn");
            
             context.$store
            .dispatch("auth/RequestOTPAction",{
              mobilenumber: this.Mobile,
            }).then((resutls) => {
              context.$wait.end("LoggingIn");
              if(resutls.Status){
              context.VerifyOTP = true;
              context.MatchOTP = resutls.Data.otpnumber;
              } else {
              context.$Notify.Error("Please verify your mobile number", "");
              }
            }).catch(() => {
              this.$Notify.Error("Something Went Wrong");
            });

          }
        });

    },
    RegisterCommuter: function ()
    {

       var context = $(window).data("RegisterUserOPTModal");

       debugger;

       if(this.FULLNAME.split(" ").length == 2){
         this.FirstName = this.FULLNAME.split(" ")[0];
         this.LastName = this.FULLNAME.split(" ")[1];
       } else if(this.FULLNAME.split("_").length == 2){
         this.FirstName = this.FULLNAME.split("_")[0];
         this.LastName = this.FULLNAME.split("_")[1];
       }else {
          this.FirstName = this.FULLNAME;
       }

      this.$Notify
        .YesNo(false,true)
        .then((value) => {
          if (value) {
            context.$wait.start("LoggingIn");

            analytics.track("Register Commuter via OTP", {
              firstname:this.FirstName,
              surname:this.LastName,
              mobilenumber:this.Mobile,
              password:this.Password,
              uid:this.uid,
            });


            context.$store
          .dispatch("auth/RegisterCommuter3rdPartyAction",{
              firstname:this.FirstName,
              surname:this.LastName,
              mobilenumber:this.Mobile,
              password:this.Password,
              uid:this.uid,
              }).then((resutls) => {
              var contexts = $(window).data("RegisterUserOPTModal");
              contexts.$wait.end("LoggingIn");
              if(resutls.Status){
                contexts.GetProfile();
                $('#userSignupModal').modal('hide');
                contexts.$Notify.Toast("Welcome, " + contexts.FirstName,true, false);
                window.location.reload();
              } else {
                context.$Notify.Error(resutls.Message, "");
              }
            }).catch(() => {
              context.$wait.end("LoggingIn");
              context.$Notify.Error("Something Went Wrong");
            });
          }
        });


    },
  },
   mounted: function() {

      $(window).data("RegisterUserOPTModal",this);

      
      function ActivatePassword(){
      const togglePassword = document.querySelector("#togglePassword");
      const password = document.querySelector("#passwordregisteruser");

      togglePassword.addEventListener("click", function () {
        
        // toggle the type attribute
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        // toggle the eye icon
        this.classList.toggle('fa-eye');
        this.classList.toggle('fa-eye-slash');
      });
    }

    $('#RegisterUserOPTModal').on('show.bs.modal', function() {
        // do something when the modal is shown
        var context = $(window).data("RegisterUserOPTModal");

        analytics.page("Register User OPT Modal","RegisterUserOPTModal");
          
        ActivatePassword();
      });

    
     
    //  const contexttemp = this;
    //  this.$store.dispatch("TA/getLOGOURL", {
    //   id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    // }).then((result) => {

    //   //debugger;
      
    //   contexttemp.LOGO = result;
    // }).catch((err) => {
    // });
  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

