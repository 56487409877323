<template>

 <div class="modal fade" id="userForgotModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>

          <div class="modal-body p-4">
              <h6 class="mb-4">Forgot password?</h6>

              <p>Enter your details below to request password reset.</p>

              <div class="mb-3">
                <label for="userForgotEmail" class="form-label fs-sm fw-bold">Mobile Number</label>
                <input type="number" v-model="mobileno" placeholder="27814560928" @keypress="isNumber($event)" class="form-control shadow-none" id="userForgotEmail" />
              </div>

              <div class="mb-3">
                <button 
                id="ForgotPassword"
                type="submit" @click="SubmitForgotPassword" class="btn btn-primary w-100 text-uppercase fw-bold"
                :disabled="!CanSubmit"
                hidden
                >
                </button>

                  <button 
                data-sitekey="6LetUo8gAAAAAHSBpAM87p1t6ITqbUNiAZpUgRxO" 
                data-callback='onSubmitForgot' 
                data-action='submit'
                class="g-recaptcha btn btn-primary w-100 text-uppercase fw-bold"
                :disabled="!CanSubmit || this.$wait.is('LoggingIn')"
                >
                 <v-wait for="LoggingIn">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Submit
                  </v-wait>
                  </button>
              </div>

            <div class="text-center text-secondary fs-sm">
              <p class="mb-0">
                <a href="#" class="" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#userSignInModal"><i class="fas fa-angle-left"></i>&nbsp;<span class="text-decoration-underline">Back</span></a>
              </p>
            </div>
            <!-- /.text-center -->
          </div>
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog modal-dialog-centered modal-sm -->
    </div>
<!-- /.modal -->  

</template>

<script>


import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('auth');

export default {
	 name: "userForgotModal",
	 props:['AddTaxiOperatorDropDownControl','AddTopSearchControl','AddTopNotificationControl','isAdminUser'],
  data: function() {
    return {
      LOGO: undefined,
      mobileno: "",
    };
  },
  computed: {
    ...mapGetters(["USERPROFILE","SELECTEDTAXIASSOCIATION"]),
    CanSubmit: function (){
      return this.mobileno != "" && this.mobileno.length >= 10;
    }
  },
  components: {
  },
  methods: {
    ...mapActions([
      "ForgotPasswordAction",
    ]),
    SubmitForgotPassword: function (){
      this.$wait.start("LoggingIn");
     

      this.ForgotPasswordAction({mobilenumber:this.mobileno}).then(response => {

        analytics.track("Forgot Password", {
            mobileno:this.mobileno,
            response: response
      });

      this.$Notify.Success("New Password Sent Via SMS");

      $('#userForgotModal').modal('hide');

      }).catch(error => {
        this.$Notify.Error("No Account Exists");
      }).finally(() => {
        this.$wait.end("LoggingIn");
      });

    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    init: function ()
    {
      this.$emit('init')
    }
  },
   mounted: function() {

     $('#userForgotModal').on('show.bs.modal', function() {
        // do something when the modal is shown
          var context = $(window).data("userForgotModal");

          analytics.page("user Forgot Modal","userForgotModal");
      });
     
    //  const contexttemp = this;
    //  this.$store.dispatch("TA/getLOGOURL", {
    //   id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    // }).then((result) => {

    //   //debugger;
      
    //   contexttemp.LOGO = result;
    // }).catch((err) => {
    // });
  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

