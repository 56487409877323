/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import configbase from '../../configurations/config';


export default {
  namespaced: true,
  state: {
    cards:[],
    frequency:[],
    banks:[],
    accounttype:[],
    switchbooking:false,
    joinwaitinglist:false,
    switchbookingid:undefined,
    bookingdetails:{
         BookingDate:"",
         BookingDateText:"",
         Rank:"",
         Destination:"",
         DestinationId:"",
         DepartureTime:"",
         DepartureId:"",
         Frequency:"",
         FrequencyId:"",
         Seats:0,
         SubTotal:0,
         BookingFee:10,
         StrikePrice:0,
         Total:0,
    },
    rentaldetails:{
      DepartureDate:"",
      DepartureDateText:"",
      ReturnDate:"",
      ReturnDateText:"",
      From:"",
      To:"",
      Trailer:false,
      Distance:0,
      DaysTravel:0,
      SubTotal:0,
      StrikePrice:0,
      RentalFee:5,
      Total:0,
 },
  selectedrental:undefined,
  },
  getters: {
    GETCARDS: state => state.cards,
    GETFREQUENCY: state => state.frequency,
    GETBANKS: state => state.banks,
    GETACCOUNTYPES: state => state.accounttype,
    GETSWITCHBOOKING: state => state.switchbooking,
    GETSWITCHBOOKINGID: state => state.switchbookingid,
    ISLOGGEDON: (state, getters, rootState, rootGetters) => {
      return rootGetters['auth/ISLOGGEDON']
    },
    USERPROFILE: (state, getters, rootState, rootGetters) => {
      return rootGetters['auth/USERPROFILE']
    },
    WALLETPROFILE: (state, getters, rootState, rootGetters) => {
      return rootGetters['auth/WALLETPROFILE']
    },
    GETBOOKINGDETAILS: state => state.bookingdetails,
    GETRENTALDETAILS: state => state.rentaldetails,
    GETSELECTEDRENTAL: state => state.selectedrental,
    JOINWAITINGLIST: state => state.joinwaitinglist,
  },

  mutations: {
    SETJOINWAITINGLIST: (state, _value) => {
      if (_value !== undefined) {
        state.joinwaitinglist = _value;
      }
    },
    SETSWITCHBOOKING: (state, _switchbooking) => {
      if (_switchbooking !== undefined) {
        state.switchbooking = _switchbooking;
      }
    },
    SETSWITCHBOOKINGID: (state, _switchbookingid) => {
      if (_switchbookingid !== undefined) {
        state.switchbookingid = _switchbookingid;
      }
    },
    SETCARDS: (state, _cards) => {
      if (_cards !== undefined) {
        state.cards = _cards;
      }
    },
    SETFREQUENCY: (state, _value) => {
      if (_value !== undefined) {
        state.frequency = _value;
      }
    },
    SETBANKS: (state, _value) => {
      if (_value !== undefined) {
        state.banks = _value;
      }
    },
    SETACCOUNTTYPE: (state, _value) => {
      if (_value !== undefined) {
        state.accounttype = _value;
      }
    },
    SETBOOKINGDETAILS: (state, _value) => {
      if (_value !== undefined) {
        state.bookingdetails = _value;
      }
    },
    SETRENTALDETAILS: (state, _value) => {
      if (_value !== undefined) {
        state.rentaldetails = _value;
      }
    },
    SETSELECTEDRENTAL: (state, _value) => {
      if (_value !== undefined) {
        state.selectedrental = _value;
      }
    },
  },
  actions: {

    /* Async */
    UpdateNextOfKinAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      FirstName,
      Surname,
      MobileNumber,
  }) {
      const url = configbase.COMMUTER_URL("AddNextOfKin");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "SystemUserId": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "SystemUserMobileNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
              "FirstName": FirstName,
              "Surname": Surname,
              "MobileNumber": MobileNumber,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    SetRentalSelectedAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, 
    selectedrental
    ) {
      return new Promise((resolve, reject) => {
        commit('SETSELECTEDRENTAL', selectedrental);
        resolve(true);
      });
    },
    GetVehicleTypesAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetAllVehicleType");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    SetRentalDetailsAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      DepartureDate,
      DepartureDateText,
      ReturnDate,
      ReturnDateText,
      From,
      To,
      Trailer,
      Distance,
      DaysTravel,
      SubTotal,
      RentalFee,
      Total,
  }) {
      return new Promise((resolve, reject) => {
        commit('SETRENTALDETAILS', {
          DepartureDate,
          DepartureDateText,
          ReturnDate,
          ReturnDateText,
          From,
          To,
          Trailer,
          Distance,
          DaysTravel,
          SubTotal,
          RentalFee,
          Total,
      });
        resolve(true);
      });
    },
    SetBookingDetailsAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      BookingDate,
      BookingDateText,
      Rank,
      Destination,
      DestinationId,
      DepartureTime,
      DepartureId,
      Frequency,
      FrequencyId,
      Seats,
      FarePrice,
      SubTotal,
      BookingFee,
      Total,
  }) {
      return new Promise((resolve, reject) => {
        commit('SETBOOKINGDETAILS', {
          BookingDate,
          BookingDateText,
          Rank,
          Destination,
          DestinationId,
          DepartureTime,
          DepartureId,
          Frequency,
          FrequencyId,
          Seats,
          FarePrice,
          SubTotal,
          BookingFee,
          Total,
      });
        resolve(true);
      });
    },
    GetDepartureTimesAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      RankId,
      RouteId,
      BookingDate
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetAvailableSeats");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "RankId": RankId.toString(),
              "RouteId": RouteId.toString(),
              "BookingDate": BookingDate.toString(),
              // "RankId": "4",
              // "RouteId": "16",
              // "BookingDate": "16-11-2021",
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetRouteByRankIdAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      RankId
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetRoutesByRankId");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "id": RankId.toString(),
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetAllFrequencyAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetAllFrequency");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetAllRanksAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetAllRanks");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    SubmitRentATaxiAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      VehicleTypeCode,
      VehicleTypeDesc,
      DepartureDate,
      ReturnDate,
      FromAddress,
      FromAddresslocation,
      ToAddress,
      ToAddresslocation,
      DistanceInKm,
      AmountPaid,
      Fee,
      BookingStatusCode,
      TransSourceCode,
      SendNotification,
      BookingRef
    }) {
      const url = configbase.FIN_COMMUTER_URL("RentATaxi");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "SystemUserId": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "MobileNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
              "vehicletypecode": VehicleTypeCode,
              "vehicletypedesc": VehicleTypeDesc,
              "departuredate": DepartureDate,
              "returndate": ReturnDate,
              "from_address": FromAddress,
              "fromaddresslocation": FromAddresslocation,
              "toaddress": ToAddress,
              "toaddresslocation": ToAddresslocation,
              "distance_inkm": DistanceInKm,
              "amountpaid": AmountPaid,
              "fee": Fee,
              "addedby": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "bookingstatuscode": BookingStatusCode,
              "transsourcecode": TransSourceCode,
              "SendNotification": SendNotification,
              "BookingRef": BookingRef,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    SwitchBookASeatAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      BookSeatsId,
      RankRoutesId,
      DepartureTimeId,
      BookSeatsSessionId,
      NoOfSeats,
      BookingDate,
      FareAmount,
      BookingStatusCode,
      SendNotification,
      FrequencyCode,
      TransactionSourceCode
    }) {
      const url = configbase.FIN_COMMUTER_URL("SwitchABooking");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "SystemUserId": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "MobileNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
              "rank_routes_id": RankRoutesId,
              "departuretime_id": DepartureTimeId,
              "bookseats_sessionid": BookSeatsSessionId,
              "noofseats": NoOfSeats,
              "bookingdate": BookingDate,
              "fare_amount": FareAmount,
              "addedby": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "bookingstatus_code": BookingStatusCode,
              "sendnotification": SendNotification,
              "frequencycode": FrequencyCode,
              "transactionsourcesode": TransactionSourceCode,
              "BookSeatsId": BookSeatsId,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    SubmitBookASeatAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      RankRoutesId,
      DepartureTimeId,
      BookSeatsSessionId,
      NoOfSeats,
      BookingDate,
      FareAmount,
      BookingStatusCode,
      SendNotification,
      FrequencyCode,
      TransactionSourceCode
    }) {
      const url = configbase.FIN_COMMUTER_URL("BookASeat");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "SystemUserId": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "MobileNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
              "rank_routes_id": RankRoutesId,
              "departuretime_id": DepartureTimeId,
              "bookseats_sessionid": BookSeatsSessionId,
              "noofseats": NoOfSeats,
              "bookingdate": BookingDate,
              "fare_amount": FareAmount,
              "addedby": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "bookingstatus_code": BookingStatusCode,
              "sendnotification": SendNotification,
              "frequencycode": FrequencyCode,
              "transactionsourcesode": TransactionSourceCode,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    CancelRentalBookingAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    },{
      Item
    }) {
      const url = configbase.FIN_COMMUTER_URL("CancelRental");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "SystemUserId": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "MobileNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
              "RentalBookingId": Item.rentataxiid,
              "BookingNo": Item.bookingnumber,
              "CancelledBy": rootGetters['auth/USERPROFILE'].SystemUsersId,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    CheckInBookingAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    },{
      Item
    }) {
      const url = configbase.FIN_AGENT_URL("CheckInCommuters");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "CheckedInBy": rootGetters['auth/USERPROFILE'].SystemUsersId, 
              "BookingNo": Item.bookingnumber,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    CancelBookingAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    },{
      Item
    }) {
      const url = configbase.FIN_COMMUTER_URL("CancelBooking");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "SystemUserId": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "MobileNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
              "BookaSeatId": Item.bookaseatid,
              "BookingNo": Item.bookingnumber,
              "CancelledBy": rootGetters['auth/USERPROFILE'].SystemUsersId,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    SetJoinWaitingListAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    },{
      joinvalue
    }) {
      commit('SETJOINWAITINGLIST', joinvalue);
    },
    CancelSwitchBookingAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }) {
      commit('SETSWITCHBOOKING', false);
      commit('SETSWITCHBOOKINGID', undefined);
    },
    SwitchBookingAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    },{
      Item
    }) {
      commit('SETSWITCHBOOKING', true);
      commit('SETSWITCHBOOKINGID', Item);
    },
    DebitOrderFrequencyAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetAllRecurring");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if(parsedResult.Status){
              commit('SETFREQUENCY', parsedResult.Data);
            }
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    BanksAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetAllBanks");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if(parsedResult.Status){
              commit('SETBANKS', parsedResult.Data);
            }
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    BankAccountTypeAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetAllAccountTypes");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if(parsedResult.Status){
              commit('SETACCOUNTTYPE', parsedResult.Data);
            }
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetBankCardsAction({
      commit,
      state,
      rootGetters,
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetBankCards");
        return new Promise((resolve, reject) => {
          //commit('SETCARDS', []);
          
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "mobileno": rootGetters['auth/USERPROFILE'].MobileNumber,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if (parsedResult.Status) {
              commit('SETCARDS', parsedResult.Data);
            } 
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {
  
          });
        });
    },
    GetAllRentalBookingsAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetRentATaxiByMobileNo");
        return new Promise((resolve, reject) => {
          
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "MobileNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetAllBookingsAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetAvailableBookingByMobileNo");
        return new Promise((resolve, reject) => {
          
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "MobileNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetAllTransactionsAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    },{
      Position,
      NumofRecords
    }) {
      const url = configbase.FIN_TRANS_URL("GetTransactions");
        return new Promise((resolve, reject) => {
          
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "MobileNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
              "Position": Position,
              "NumofRecords": NumofRecords,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetDebitOrdersAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetDebitOrders");
        return new Promise((resolve, reject) => {
          
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "mobileno": rootGetters['auth/USERPROFILE'].MobileNumber,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    CancelDebitOrderAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      contractReference,
      clientReference,
      mandateID,
    }) {
      const url = configbase.FIN_DEBITORDERS_URL("CancelDebitOrder");
        return new Promise((resolve, reject) => {
          
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "SystemUserId": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "DebtorPhoneNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
              "contractReference": contractReference,
              "clientReference": clientReference,
              "mandateID": mandateID,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    TopUpByDebitOrderAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      Frequency,
      CollectionDay,
      DebtorId,
      DebtorAccountNumber,
      DebtorBankId,
      DebtorAccountType,
      CollectionAmount,
      StartDate,
      DebtorBranchNumber,
    }) {
      const url = configbase.FIN_DEBITORDERS_URL("");
        return new Promise((resolve, reject) => {
          
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "SystemUserId": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "Frequency": Frequency,
              "CollectionDay": CollectionDay,
              "ClientReference": "BookASeat DebitOrder " + CollectionDay,
              "DebtorName": rootGetters['auth/USERPROFILE'].FirstName + ' ' + rootGetters['auth/USERPROFILE'].LastName,
              "DebtorId": DebtorId,
              "DebtorIdType": "2",
              "DebtorAccountNumber": DebtorAccountNumber,
              "DebtorAccountType": DebtorAccountType.toString(),
              "DebtorBankId": DebtorBankId.toString(),
              "DebtorBranchNumber": DebtorBranchNumber,
              "DebtorPhoneNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
              "CollectionAmount": CollectionAmount,
              "StartDate": StartDate,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    TopUpByVoucherAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      voucherpin,
    }) {
      const url = configbase.FIN_TOPUPTRANS_URL("TransferVoucherToWallet");
        return new Promise((resolve, reject) => {
          
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "reedeemby": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "voucherpin": voucherpin,
              "MobileNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    TopUpWalletAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      BankCardId,
      CardNo,
      Amount,
    }) {
      const url = configbase.FIN_BANKCARDS_URL("Charge");
        return new Promise((resolve, reject) => {
          
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "SystemUserId": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "bankcardid": BankCardId,
              "cardno": CardNo,
              "email": rootGetters['auth/WALLETPROFILE'].email,
              "amount": Amount,
              "addedby": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "TransReference": "TopUp " + Amount,
              "TransactionSourceCode": "DC",
              "MobileNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
              "FullName": rootGetters['auth/USERPROFILE'].FirstName,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    AddBankCardAndCharge({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      transaction,
      reference,
      response,
    }) {
      const url = configbase.FIN_BANKCARDS_URL("AddBankCardAndCharge");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify({
              "SystemUserId": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "TransactionId": transaction,
              "TransactionRef": reference,
              "MobileNo": rootGetters['auth/USERPROFILE'].MobileNumber,
              "Payload": JSON.stringify(response),
              "TransactionSourceCode":"DC"/*BookASeat */
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
  }

};