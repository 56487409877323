<template>
 
    <section id="searchbox-hero-3" class="section vh-lg-100 min-h-480px justify-content-center py-5 py-lg-6">
      <div class="bg bg-img position-absolute o-90 bg-dark" data-bg-img=".img">
        <img src="/assets/img/rankpdf.png" id="bgpic" class="img" alt="Image" />

        <div class="overlay bg-dark position-absolute o-25"></div>
      </div>
      <!-- /.bg bg-img position-absolute o-90 bg-dark -->

      <div class="container-xl position-relative py-5">
        <div class="row g-4">
          <div class="col-12 col-lg-6 d-flex flex-column justify-content-center">
  
            <h1 class="display-4 mb-4 text-white">
               We Help You<br />
              <span data-js-typing="Book A Seat In A,Rent A"></span><span class="">Taxi</span>
            </h1>

            <p class="me-lg-3 mb-4 text-white fs-5">No More Queuing, No More Waiting</p>

             <div class="row g-0">
          <div class="mt-0">

             <div class="download-area">
              <ul class="list-inline mb-0">
                <li class="android-btn list-inline-item"><a href="tel:0872363065"><img src="/assets/img/buttons/CallUs_Button.png" alt="Call Us"></a></li>
                <!-- <li class="android-btn list-inline-item"><a href="mailto:bookings@tpay.africa"><img src="/assets/img/buttons/Email_Button.png" alt="Email Us"></a></li>
                 -->
                 <li class="android-btn list-inline-item"><a href="https://api.whatsapp.com/send?phone=27670053610&text=Hi"><img src="/assets/img/buttons/Whatsapp_Button.png" alt="Use Our WhatsApp Service"></a></li>
                </ul>
          
              <ul class="list-inline">
                  <!-- <li class="android-btn list-inline-item"><a href="https://api.whatsapp.com/send?phone=27670053610&text=Hi"><img src="/assets/img/buttons/Whatsapp_Button.png" alt="Use Our WhatsApp Service"></a></li>
                   -->
                  <li class="android-btn list-inline-item">
                    <!-- <a href="tel:*134*8111##"><img src="/assets/img/buttons/USSD_Button_BW.png" alt="Use Our USSD Service"></a> -->
                  </li>
              </ul>
          </div>

          </div>
             </div>

           

          </div>
          <!-- /.col-12 col-lg-6 -->

          <div class="col-12 col-lg-2"></div>

          <div id="searchbox-hero-3Searchbox" class="searchbox position-relative col-12 col-lg-4 pb-7">
            <a class="form-options-backdrop modal-backdrop collapsed" data-bs-toggle="collapse" href="#searchbox-hero-3FormOptionsCollapse" role="button" aria-expanded="false" aria-controls="searchbox-hero-3FormOptionsCollapse"></a>

            <div class="searchbox-form position-relative w-100">
              <div class="bg position-absolute bg-white rounded-top shadow-sm"></div>
              <div class="position-relative pt-4 px-4">
                <h1 class="fs-3 mb-2 position-relative">Booking Made Easy</h1>


                <div class="mb-0 position-relative d-flex flex-column">
                  <div class="btn-group" role="group">
                    <input type="radio" class="btn-check" value="bookaseattab" name="bookingtap" id="bookaseattab" checked />
                    <label class="btn btn-primary btn-default shadow-none fw-bold" for="bookaseattab"><i class="fas fa-check-circle"></i>Book&nbsp;A&nbsp;Seat</label>
                    <input type="radio" class="btn-check" value="rentataxitab" name="bookingtap" id="rentataxitab" />
                    <label class="btn btn-primary btn-default shadow-none fw-bold" for="rentataxitab"><i class="fas fa-check-circle"></i>Rent&nbsp;A&nbsp;Taxi</label>
                  </div>
                  <!-- /.btn-group -->
                </div>

                <div class="bookaseat">

                   <div class="row gx-2">

                      <div class="col-12 col-auto">
                        <div class="mb-0">
                       <label class="form-label text-secondary my-1"><small>Date</small></label>
                        <div class="mb-0 position-relative">
                          <input autocomplete="off" value="" type="text" v-model="BookingDate" class="form-control shadow-none" placeholder="Select Booking Date" name="dateofdeparture" />
                        </div>
                       </div>
                      </div>

                      <div class="col-12 col-auto">
                        <div class="mb-0">
                       <label class="form-label text-secondary my-1"><small>Rank</small></label>
                      <select title="Select Rank..." v-on:change="RankChange" data-live-search="true" v-model="RankId" class="selectpickerrank form-control shadow-none">
                        <option v-bind:key="item.Id" v-for="item in ALLRANKS" v-bind:value="item">
                        {{item.Name}}
                        </option>
                      </select>
                        <!-- /.btn-group btn-select-dropdown -->
                       
                       </div>
                      </div>

                        <div class="col-12 col-auto">
                        <div class="mb-0">
                           <label class="form-label text-secondary my-1"><small>Destination</small></label>
                          <select :disabled="ALLROUTES.length==0"  title="Select Destination..." v-on:change="RouteChange" data-live-search="true" v-model="RouteId" class="selectpickerroute form-control shadow-none">
                          <option v-bind:key="item.Id" v-for="item in ALLROUTES" v-bind:value="item">
                          {{item.Name}}
                          </option>
                        </select>
                       </div>
                      </div>

                       <div class="col-12 col-auto">
                        <div class="mb-0">
                            <label class="form-label text-secondary my-1"><small>Departure Time</small></label>
                            <select :disabled="ALLDEPARTURETIMES.length==0" title="Select Departure Time..." data-live-search="true" v-on:change="DepartureTimeChange" v-model="DepartureTimeId" class="selectpickerDeparture form-control shadow-none">
                            <option v-bind:key="item.id" v-for="item in ALLDEPARTURETIMES" v-bind:value="item">
                            {{item.departuretime_desc}} (Seats:{{item.available_seats}}) 
                            {{item.waitinglist > 0 ? '('+item.waitinglist+' In Waiting List)' : ''}}
                            </option>
                          </select>
                          
                       </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="mb-3">
                           <label class="form-label text-secondary my-1"><small>Frequency</small></label>
                          <select :disabled="ALLFREQUENCIES.length==0" title="Select Frequency..." data-live-search="true" v-model="FrequencyId" class="selectpickerFrequency form-control shadow-none">
                          <option v-bind:key="item.id" v-for="item in ALLFREQUENCIES" v-bind:value="item">
                          {{item.name}}
                          </option>
                        </select>
                       </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="mb-3">
                          <label for="BedroomsAny" class="form-label fs-sm mt-1 mb-0">People</label>
                          <div class="row position-relative g-0 my-2" data-check-range>
                            
                            <div class="col">
                              <div class="btn-group btn-range w-100 dropdown">
                                <button type="button" class="btn btn-outline-dark btn-default dropdown-toggle d-flex align-items-center justify-content-between shadow-none allow-hover" data-bs-toggle="dropdown" aria-expanded="false">1</button>
                                <div class="dropdown-menu dropdown-menu-end w-75 px-3 pt-2 pb-1 shadow-sm" data-bs-popper="none">
                                  <input type="range" v-model="SeatsId" min="1" max="25" step="1" class="form-range" name="Bedrooms" value="1" data-suffix="" id="searchbox-hero-3Bedrooms" />
                                </div>
                              </div>
                              <!-- /.btn-group btn-range w-100 position-initial -->
                            </div>
                            <!-- /.col -->
                          </div>
                       </div>
                      </div>


                       <div class="col-12 col-auto">
                        <div class="mb-0">
                          <div class="d-flex position-relative flex-column bookaseat">
                
                  <!-- <button v-if="!GETSWITCHBOOKING" class="btn btn-success d-block fw-bold" 
                  data-bs-dismiss="modal" data-bs-target="#BookASeatDialog">Book Now</button> -->

                   <span v-show="ISSUBMITBUY && REMAINING>0" class="btn-primary badge text-right">No Of Bookings: {{REMAINING}}</span>

                    <!--!ISSUBMITBUY || this.$wait.is('BookNow')-->
                   <button v-if="!GETSWITCHBOOKING && !JOINWAITINGLIST" class="btn btn-success d-block fw-bold g-recaptcha" 
                  @click='BookNow'
                  :disabled="!ISSUBMITBUY || this.$wait.is('BookNow')"
                  >
                  <v-wait for="BookNow">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                  
                    Book Now <span v-show="ISSUBMITBUY && PRICE > 0" >R{{PRICE}}</span>
                  </v-wait>
                  </button>

                  <span v-if="GETSWITCHBOOKING" class="btn-success badge">Booking No: {{GETSWITCHBOOKINGID.bookingnumber}}</span>

                  <div v-if="GETSWITCHBOOKING" class="btn-group" role="group" aria-label="Basic mixed styles example">
                    <button type="button" class="btn btn-warning d-block fw-bold"
                    @click="SwitchNow" 
                    :disabled="!ISSUBMITBUY || this.$wait.is('SwitchNow')">
                       <v-wait for="SwitchNow">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                      Change <span v-show="ISSUBMITBUY && PRICE > 0" >R{{PRICE}}</span>
                    </v-wait>
                    </button>
                    <button @click="CancelSwitchBookingAction" type="button" class="btn btn-primary">Reset</button>
                  </div>

                  <div v-if="!GETSWITCHBOOKING && JOINWAITINGLIST" class="btn-group" role="group" aria-label="Basic mixed styles example">
                    <button type="button"  @click="JoinWaitingListAction"  class="btn btn-warning d-block fw-bold"
                    :disabled="!ISSUBMITBUY || this.$wait.is('SwitchNow')">
                       <v-wait for="SwitchNow">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                      Join Waiting List <span v-show="ISSUBMITBUY && PRICE > 0" >R{{PRICE}}</span>
                    </v-wait>
                    </button>
                    <!-- <button @click="CancelWaitinglistBookingAction" type="button" class="btn btn-primary">Reset</button> -->
                  </div>

                  
                </div>
                       </div>
                          
                      </div>


                <!-- /.col-12 -->
                   </div>


                
                     
              </div>

              

              <div class="rentataxi">

                 <div class="row gx-2">
                    <div class="col-12 col-auto">
                        <div class="mb-0">
                       <label class="form-label text-secondary my-1"><small>Departure And Return Date</small></label>
                        <div class="mb-0 position-relative">
                          <input type="text" class="form-control form-control shadow-none py-2" value="" autocomplete="off" placeholder="Select Departure And Return Date" name="dateofdeparturerent" />
                        </div>
                       </div>
                      </div>
                 

                    <div class="col-12 col-auto">
                        <div class="mb-0">
                       <label class="form-label text-secondary my-1"><small>From</small></label>
                         <div class="mb-0 position-relative">
                          <input type="text" value="" autocomplete="off" @keydown="ISSUBMITRENT" class="form-control form-control shadow-none py-2" id="from" />
                        </div>
                       </div>
                       </div>

                    <div class="col-12 col-auto">
                        <div class="mb-0">
                       <label class="form-label text-secondary my-1"><small>To</small></label>
                        <div class="mb-0 position-relative">
                        <input type="text" value="" autocomplete="off" @keydown="ISSUBMITRENT" class="form-control form-control shadow-none py-2" id="to" />
                      </div>
                       </div>
                       </div>

                       <div class="col-12 col-sm-6">
                        <div class="mb-1">
                           <label class="form-label text-secondary my-1"><small>Distance</small></label>
                            <input type="text" v-model="DISTANCETEXT" disabled="disabled" value="" autocomplete="off" class="form-control form-control shadow-none py-2" />
                       </div>
                      </div>
                      

                      <div class="col-12 col-sm-6">
                        <div class="mb-0">
                           <label class="form-label text-secondary my-1"><small>Travel Time</small></label>
                            <input type="text" v-model="TRAVELTIME" disabled="disabled" value="" autocomplete="off" class="form-control form-control shadow-none py-2" />
                          </div>
                      </div>

                      <div class="col-12 col-auto">
                        <div class="mb-2">
                          <label for="BedroomsAny" class="form-label fw-bold fs-sm mt-0 mb-0">Need Trailer?</label>
                        <div class="row position-relative g-0" data-check-range>
                          <div class="col pt-0">
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" v-model="Trailer" id="listing-search-sidebar-1BedroomsAny" name="BedroomsAny" value="true" checked="checked" />
                              <label class="form-check-label"  for="listing-search-sidebar-1BedroomsAny">{{Trailer ? 'Yes' : 'No'}}</label>
                            </div>
                          </div>
                          <!-- /.col pt-2 -->
                          
                          <!--<div class="col-5"></div>-->
                        </div>
                          </div>
                      </div>



             </div>


                <div class="d-flex position-relative flex-column">
                  <button class="btn btn-success d-block fw-bold" @click="RentNow" type="submit"
                  :disabled="!ISSUBMITRENT || this.$wait.is('RentNow')"
                  >
                   <v-wait for="RentNow">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Rent Now
                  </v-wait>
                  </button>
                </div>
              </div>
              <!-- /.position-relative -->

            </div>

              <div class="position-absolute w-100 px-4">
                <div class="bg position-absolute bg-white rounded-bottom shadow-sm"></div>

            
                <div class="position-relative py-3">
                  <h6 class="fs-sm my-0">
                  <label class="form-label text-secondary my-0"><button class="btn btn-link p-0 shadow-none" data-bs-toggle="modal" data-bs-target="#MissingDepartureTimeModal" style="color:blue">Feedback/Suggestion? Tell Us</button></label>
                
                  </h6>
                </div>
                <!-- /.position-relative -->
              </div>
              <!-- /.position-absolute w-100 -->

              <a class="searchbox-btn-close btn-close form-is-focus position-absolute top-0 start-100 translate-middle" data-bs-toggle="collapse" href="#searchbox-hero-3FormOptionsCollapse" role="button" aria-expanded="false" aria-controls="searchbox-hero-3FormOptionsCollapse"><span class="btn-close-icon"></span></a>
            </div>
            <!-- /.searchbox-form -->
          </div>
          <!-- /#searchbox.searchbox -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-xl position-relative -->
    </section>
    <!-- #searchbox-hero-3.section vh-lg-100 min-h-480px justify-content-center py-5 py-lg-6 -->
</template>

<script>


import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('commuter');

export default {
	 name: "searchbox",
	 props:{
   },
  data: function() {
    return {
      Trailer: false,
      DepartureDate: '',
      DepartureDateText: '',
      ReturnDate: '',
      ReturnDateText: '',
      Distance: '',
      DistanceId: undefined,
      ETA: '',
      RentalFee: '',
      BookingDate: '',
      BookingDateText: '',
      DaysTravel: 0,
      RankId: undefined,
      RouteId: undefined,
      DepartureTimeId: undefined,
      FrequencyId: undefined,
      SeatsId: 1,
      RentFrom: '',
      RentTo: '',
      ALLRANKS: [],
      ALLROUTES: [],
      ALLDEPARTURETIMES: [],
      ALLFREQUENCIES: [],
      REMAININGDAYSINMONTH:0,
      REMAININGDAYSINWEEK:0,
      REMAINING:1,
    };
  },
  computed: {
    ...mapGetters(["GETSWITCHBOOKING","GETSWITCHBOOKINGID","ISLOGGEDON","JOINWAITINGLIST"]),
     ISSTART: function(){
      return this.BookingDate != '';
    },
    DISTANCETEXT: function(){

      // this.RentFrom = $("#from").val();
      // this.RentTo = $("#to").val();

      if(this.DistanceId != undefined){
        return this.DistanceId.distance.text
      } else {
        return '';
      }
    },
    TRAVELTIME: function(){

      // this.RentFrom = $("#from").val();
      // this.RentTo = $("#to").val();

       if(this.DistanceId != undefined){
        return this.DistanceId.duration.text
      } else {
        return '';
      }

    },
    ISSUBMITRENT: function(){

      // this.RentFrom = $("#from").val();
      // this.RentTo = $("#to").val();

      return this.RentFrom != "" && this.RentTo != "" && this.DepartureDate != "" && this.ReturnDate != "";
    },
    PRICE: function(){
      if(this.RouteId){

        if(this.FrequencyId != undefined){

        if(this.FrequencyId.code == "WKY"){
          this.REMAINING = this.REMAININGDAYSINWEEK
        } else if(this.FrequencyId.code == "MLY"){
          this.REMAINING = this.REMAININGDAYSINMONTH
        } else {
          this.REMAINING = 1
        }

        }

        return parseFloat(this.SeatsId) * parseFloat(this.RouteId.Price) * this.REMAINING;    
      }
      else
      return 0;
    },
     ISSUBMITBUY: function(){
      return this.BookingDate != '' && this.RankId != undefined && this.RouteId != undefined && this.DepartureTimeId != undefined 
      && this.FrequencyId != undefined && this.SeatsId > 0;    
      },
      SELECTEDROUTE: function(){
        if(this.RankId != undefined){
          debugger
          return GetRouteByRankId(this.RankId.id);
        }

      },
  },
  components: {
  },
  methods: {
    CancelSwitchBookingAction: function ()
    {
      this.$store.dispatch('commuter/CancelSwitchBookingAction');
    },
    CancelWaitinglistBookingAction: function ()
    {
      this.$store.dispatch('commuter/SetJoinWaitingListAction',{
        joinvalue:false
      });
    },
    SwitchNow: function ()
    {
      
      if(!this.ISLOGGEDON){
        $('#userSignInModal').modal('show');
        } else {
          

          //alert(JSON.stringify(this.RouteId));

          analytics.track("Switch Book A Seat Dialog", {
              BookingDate:this.BookingDate,
              BookingDateText:this.BookingDateText,
              Rank:this.RankId.name,
              Destination:this.RouteId.Name,
              DestinationId:this.RouteId,
              DepartureTime:this.DepartureTimeId.from_date,
              DepartureId:this.DepartureTimeId,
              Frequency:this.FrequencyId.name,
              FrequencyId:this.FrequencyId,
              Seats:parseInt(this.SeatsId),
              FarePrice:this.RouteId.Price,
              CancelledBookingNo: this.GETSWITCHBOOKINGID.bookingnumber,
              SubTotal:this.PRICE,
              BookingFee:this.DepartureTimeId.bookingfee,
              Total:this.PRICE,
          });

           this.$store.dispatch('commuter/SetBookingDetailsAction',{
              BookingDate:this.BookingDate,
              BookingDateText:this.BookingDateText,
              Rank:this.RankId.name,
              Destination:this.RouteId.Name,
              DestinationId:this.RouteId,
              DepartureTime:this.DepartureTimeId.from_date,
              DepartureId:this.DepartureTimeId,
              Frequency:this.FrequencyId.name,
              FrequencyId:this.FrequencyId,
              Seats:parseInt(this.SeatsId),
              FarePrice:this.RouteId.Price,
              SubTotal:this.PRICE,
              BookingFee:this.DepartureTimeId.bookingfee,
              Total:this.PRICE,
          }).then(()=>{
            debugger;
            $('#BookASeatDialog').modal('show');

            

          });
          //this.$wait.start("BookNow");
        
        } 
    },
    JoinWaitingListAction: function ()
    {
      if(!this.ISLOGGEDON){
        $('#userSignInModal').modal('show');
        } else {
          

          //alert(JSON.stringify(this.RouteId));

          analytics.track("Show Book A Seat Dialog", {
              BookingDate:this.BookingDate,
              BookingDateText:this.BookingDateText,
              Rank:this.RankId.name,
              Destination:this.RouteId.Name,
              DestinationId:this.RouteId,
              DepartureTime:this.DepartureTimeId.from_date,
              DepartureId:this.DepartureTimeId,
              Frequency:this.FrequencyId.name,
              FrequencyId:this.FrequencyId,
              Seats:parseInt(this.SeatsId),
              FarePrice:this.RouteId.Price,
              SubTotal:this.PRICE,
              BookingFee:this.DepartureTimeId.bookingfee,
              Total:this.PRICE,
          });

          this.$store.dispatch('commuter/CancelSwitchBookingAction');

           this.$store.dispatch('commuter/SetBookingDetailsAction',{
              BookingDate:this.BookingDate,
              BookingDateText:this.BookingDateText,
              Rank:this.RankId.name,
              Destination:this.RouteId.Name,
              DestinationId:this.RouteId,
              DepartureTime:this.DepartureTimeId.from_date,
              DepartureId:this.DepartureTimeId,
              Frequency:this.FrequencyId.name,
              FrequencyId:this.FrequencyId,
              Seats:parseInt(this.SeatsId),
              FarePrice:this.RouteId.Price,
              SubTotal:this.PRICE,
              BookingFee:this.DepartureTimeId.bookingfee,
              Total:this.PRICE,
          }).then(()=>{
            $('#BookASeatDialog').modal('show');

            

          });
          //this.$wait.start("BookNow");
        
        } 
    },
    BookNow: function ()
    {
      if(!this.ISLOGGEDON){
        $('#userSignInModal').modal('show');
        $('#userSignInModal').on('hide.bs.modal', function(){
          // do it here
          var searchboxcontext = $(window).data("searchboxcontext");
          searchboxcontext.BookNow()
        });
        } else {
          

          //alert(JSON.stringify(this.RouteId));

          analytics.track("Show Book A Seat Dialog", {
              BookingDate:this.BookingDate,
              BookingDateText:this.BookingDateText,
              Rank:this.RankId.name,
              Destination:this.RouteId.Name,
              DestinationId:this.RouteId,
              DepartureTime:this.DepartureTimeId.from_date,
              DepartureId:this.DepartureTimeId,
              Frequency:this.FrequencyId.name,
              FrequencyId:this.FrequencyId,
              Seats:parseInt(this.SeatsId),
              FarePrice:this.RouteId.Price,
              SubTotal:this.PRICE,
              BookingFee:this.DepartureTimeId.bookingfee,
              Total:this.PRICE,
          });

          this.$store.dispatch('commuter/CancelSwitchBookingAction');

          

           this.$store.dispatch('commuter/SetBookingDetailsAction',{
              BookingDate:this.BookingDate,
              BookingDateText:this.BookingDateText,
              Rank:this.RankId.name,
              Destination:this.RouteId.Name,
              DestinationId:this.RouteId,
              DepartureTime:this.DepartureTimeId.from_date,
              DepartureId:this.DepartureTimeId,
              Frequency:this.FrequencyId.name,
              FrequencyId:this.FrequencyId,
              Seats:parseInt(this.SeatsId),
              FarePrice:this.RouteId.Price,
              SubTotal:this.PRICE,
              BookingFee:this.DepartureTimeId.bookingfee,
              Total:this.PRICE,
          }).then(()=>{
            $('#BookASeatDialog').modal('show');

          });
          //this.$wait.start("BookNow");
        
        } 
    },
    RentNow: function ()
    {
      if(!this.ISLOGGEDON){
        $('#userSignInModal').modal('show');
        $('#userSignInModal').on('hide.bs.modal', function(){
          // do it here
          var searchboxcontext = $(window).data("searchboxcontext");
          searchboxcontext.RentNow()
        });
        } else {

          //alert(JSON.stringify(this.Distance));

          if(parseInt(this.Distance) < 100000){ /* 100km */
            this.Distance = '110000'
          }
            
          analytics.track("Show Rent A Taxi Dialog", {
              DepartureDate:this.DepartureDate,
              DepartureDateText:this.DepartureDateText,
              ReturnDate:this.ReturnDate,
              ReturnDateText:this.ReturnDateText,
              From:this.RentFrom,
              To:this.RentTo,
              Distance:parseInt(this.Distance),
              DaysTravel: this.DaysTravel,
              SubTotal:0,
              RentalFee:10,
              Total:0,
          });

          this.$store.dispatch('commuter/SetRentalDetailsAction',{
              DepartureDate:this.DepartureDate,
              DepartureDateText:this.DepartureDateText,
              ReturnDate:this.ReturnDate,
              ReturnDateText:this.ReturnDateText,
              From:this.RentFrom,
              To:this.RentTo,
              Trailer:this.Trailer,
              Distance:parseInt(this.Distance),
              DaysTravel: this.DaysTravel,
              SubTotal:0,
              RentalFee:10,
              Total:0,
          }).then(()=>{
            $('#PreviewRentATaxiModals').modal('show');
          });
        
        } 
    },
    GetAllRanks: function ()
    {
      debugger
      this.$store.dispatch('commuter/GetAllRanksAction').then((results)=>{
        if(results.Status){
          this.ALLRANKS = results.Data;
        }
      }).catch((err)=>{
        console.log(err)

      }).finally(()=>{
        $('.selectpickerrank').selectpicker();
      });
    },
    GetAllFrequency: function ()
    {
      this.$store.dispatch('commuter/GetAllFrequencyAction').then((results)=>{
        if(results.Status){
          this.ALLFREQUENCIES = results.Data;
        }
      }).catch((err)=>{

      }).finally(()=>{
        $('.selectpickerFrequency').selectpicker();
        $('.selectpickerFrequency').selectpicker('refresh');
      });
    },
    RankChange: function ()
    {
      if(this.BookingDate == null || this.BookingDate === ''){
        this.$Notify.Error("Please Enter Date","");
      }

      this.$store.dispatch('commuter/GetRouteByRankIdAction',{
        RankId:this.RankId.Id
      }).then((results)=>{
        if(results.Status){
          this.ALLROUTES = results.Data;
        }
      }).catch((err)=>{

      }).finally(()=>{
        $('.selectpickerroute').selectpicker();
        $('.selectpickerroute').selectpicker('refresh');
      });
    },
    RouteChange: function ()
    {
      
      debugger
     

      //alert(JSON.stringify(this.RouteId));
      this.$store.dispatch('commuter/GetDepartureTimesAction',{
        RankId:this.RouteId.FromRankID,
        RouteId:this.RouteId.Id,
        BookingDate:this.BookingDate
      }).then((results)=>{
        if(results.Status){
          this.ALLDEPARTURETIMES = results.Data;
        } else {
          this.$Notify.Error(results.Message,"");
          //this.$Notify.Error(results.Data.ErrorMessage[0].ErrorMessage,"");
        }
      }).catch((err)=>{
          this.$Notify.Error(err.Message,"");
      }).finally(()=>{
        $('.selectpickerDeparture').selectpicker();
        $('.selectpickerDeparture').selectpicker('refresh');
      });
    },
    DepartureTimeChange: function ()
    {

      if(this.DepartureTimeId.available_seats === 0) {
        this.$store.dispatch('commuter/SetJoinWaitingListAction',{
          joinvalue:true
        });
      } else {
        this.$store.dispatch('commuter/SetJoinWaitingListAction',{
          joinvalue:false
        });
      }
    },
  },
   mounted: function() {

   
    $('#rentataxitab').click(function() {
      initAutocomplete();
    });

    

     this.GetAllRanks();
     this.GetAllFrequency();

      this.$store.dispatch('commuter/SetJoinWaitingListAction',{
        joinvalue:false
      });

     $(window).data("searchboxcontext",this);

       $('input[name="dateofdeparture"]').daterangepicker({
        singleDatePicker: true,
        showDropdowns: false,
        minYear: 1901,
        minDate: moment(),
        maxYear: parseInt(moment().format('YYYY'),10)
      }, function(start, end, label) {

        var context = $(window).data("searchboxcontext");
        //03-11-2021
        context.BookingDate = start.format('DD-MM-YYYY');
        context.BookingDateText = start.format('DD MMMM YYYY');

        context.REMAININGDAYSINMONTH = getWeekdaysInMonth(
          parseInt(start.format('DD'))
          ,parseInt(start.format('MM')),
         parseInt(start.format('YYYY')))
         
       context.REMAININGDAYSINWEEK = 6 - start.day();

        //alert(start.format('YYYY-MM-DD'))
        //alert(moment().diff(start, 'YYYY-MM-DD'))
        // var years = moment().diff(start, 'years');
        // alert("You are " + years + " years old!");
        // REMAININGDAYSINMONTH:0,
      // REMAININGDAYSINWEEK:0,
      });

       function DaysInWeek(days,month,year) {
        var day = new Date(year, month, days).getDay();
        return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][day]
      }

      function isWeekday(year, month, day) {
        var day = new Date(year, month, day).getDay();
        return day !=0 && day !=6;
      }

      function daysInMonth(iMonth, iYear)
        {
        return 32 - new Date(iYear, iMonth, 32).getDate();
        }

        function getWeekdaysInMonth(day,month, year) {
          var days = daysInMonth(month, year);
          var weekdays = 0;
          for(var i=day-1; i<= days; i++) {
              if (isWeekday(year, month, i)) weekdays++;
          }
          return weekdays;
          }




           $('input[name="dateofdeparturerent"]').daterangepicker({
            singleDatePicker: false,
            showDropdowns: true,
            timePicker: true,
            locale: {
              format: 'DD/MM/YYYY h:mm A'
            },
            minYear: 1901,
            minDate: moment(),
            maxYear: parseInt(moment().format('YYYY'),10)
      }, function(start, end, label) {

        var context = $(window).data("searchboxcontext");


        //"2022-05-15",
        context.DepartureDate = start.format('YYYY-MM-DD hh:mm');
        context.DepartureDateText = start.format('DD MMMM YYYY hh:mm');

        context.ReturnDate = end.format('YYYY-MM-DD hh:mm');
        context.ReturnDateText = end.format('DD MMMM YYYY hh:mm');

        //context.DaysTravel = end.diff(start, 'days') + 1;

        if(end.diff(start, 'hours') < 24){
          context.DaysTravel = 1;
        } else {
          context.DaysTravel = end.diff(start, 'days') + 1;
        }

        //context.DaysTravel = end.diff(start, 'hours');


        //alert(start.format('YYYY-MM-DD'))
        //alert(moment().diff(start, 'YYYY-MM-DD'))
        // var years = moment().diff(start, 'years');
        // alert("You are " + years + " years old!");
        // REMAININGDAYSINMONTH:0,
      // REMAININGDAYSINWEEK:0,
      });

      $('input[name="dateofdeparturerent"]').val('');

    //  $('.selectpicker').selectpicker({
    //     liveSearch:true,
    //  });

  },
}
   
</script>

<style scoped>

</style>

