<template>

   <div class="modal fade" id="EditUserModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>

          <div class="modal-body p-4">
            <h6 class="mb-4">Update Profile</h6>


              <div class="row gx-2">
                <div class="col-12 col-sm-6">
                  <div class="mb-3">
                    <label for="userSignupFirstName" class="form-label fs-sm fw-bold">First Name</label>
                    <input type="text" required v-model="FirstName" placeholder="Enter First Name" class="form-control shadow-none" id="userSignupFirstName" />
                  </div>
                </div>
                <!-- /.col-12 -->

                <div class="col-12 col-sm-6">
                  <div class="mb-3">
                    <label for="userSignupLastName" class="form-label fs-sm fw-bold">Last Name</label>
                    <input type="text" required v-model="LastName" placeholder="Enter Last Name" class="form-control shadow-none" id="userSignupLastName" />
                  </div>
                </div>
                <!-- /.col-12 -->
                <div class="col-12">
                  <div class="mb-3">
                    <label for="userSignupLastName" class="form-label fs-sm fw-bold">ID Number</label>
                    <input type="text" required v-model="IDNumber" placeholder="Enter ID Number" class="form-control shadow-none" id="userSignupIDNumber" />
                  </div>
                </div>
                <!-- /.col-12 -->
              </div>
              <!-- /.row -->

            <div class="mb-3">
                <label for="userSignInEmail" class="form-label fs-sm fw-bold">New Password(5 Digits)</label>
               <div class="input-group">
              <span class="input-group-text"><i class="fas fa-lock"></i></span>
              <input class="form-control" :class="MATCHED ? 'alert-success' : 'alert-danger'" @keypress="isNumber($event)" type="password" id="passwordsigninedit" name="password" minlength="5" maxlength="5" required 
              v-model="Password" placeholder="Enter New Password"
               >
              <span class="input-group-text">
                <i class="far fa-eye" id="togglePasswordedit" 
              style="cursor: pointer"></i>
              </span>
            </div>
          </div>


            

               <div class="mb-3">
                <button type="submit" @click="UpdateCommuter" class="btn btn-primary w-100 text-uppercase fw-bold"
                :disabled="!CanSubmit || this.$wait.is('LoggingIn')"
                >
                 <v-wait for="LoggingIn">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                     Edit
                  </v-wait>
                </button>
              </div>

            <!-- /.text-center -->
          </div>
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog modal-dialog-centered modal-sm -->
    </div>
    <!-- /.modal -->

</template>

<script>

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('auth');

export default {
	 name: "userSignupModal",
	 props:['AddTaxiOperatorDropDownControl'],
  data: function() {
    return {
      FirstName: '',
      LastName: '',
      Mobile: '',
      Password: '',
      IDNumber: '',
      OTP: '',
      VerifyOTP: false,
      MatchOTP: '',
    };
  },
  computed: {
    ...mapGetters(["USERPROFILE","WALLETPROFILE"]),
    CanSubmit: function (){
      return this.FirstName != "" && this.LastName != "" && this.IDNumber != ""
    },
     MATCHED: function (){
      return this.Password.length === 5
    },
  },
  components: {
  },
  methods: {
    ...mapActions([
      "RegisterCommuterAction",
      "RequestOTPAction",
      "GetProfile",
    ]),
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    LoadData: function ()
    {
      
      this.FirstName = this.WALLETPROFILE.firstname;
      this.LastName = this.WALLETPROFILE.surname;
      this.IDNumber = this.WALLETPROFILE.idnumber;
      
    },
    UpdateCommuter: function ()
    {

      if(this.Password != ''){
          if(this.Password.length != 5){
            this.$Notify.Error("Password Must Be 5 Digits", "");
          }
      }

      this.$Notify
        .YesNo(false,true)
        .then((value) => {
          if (value) {
             var context = $(window).data("EditUserModal");
              context.$wait.start("LoggingIn");

              analytics.track("Update Commuter", {
                firstname:this.FirstName,
                surname:this.LastName,
                mobilenumber:this.Mobile,
                idnumber: this.IDNumber,
                password:this.Password,
              });

              context.$store
              .dispatch("auth/UpdateCommuterAction",{
              firstname:this.FirstName,
              surname:this.LastName,
              mobilenumber:this.Mobile,
              idnumber: this.IDNumber,
              password:this.Password,
              }).then((resutls) => {
              this.$wait.end("LoggingIn");
              if(resutls.Status){
                  this.$store
                .dispatch("auth/GetProfile");
                $('#EditUserModal').modal('hide');
                this.$Notify.Toast("Profile Updated Successfully",true, false);
              } else {
                this.$Notify.Error(resutls.Message, "");
              }
            }).catch(() => {
              this.$wait.end("LoggingIn");
              this.$Notify.Error("Something Went Wrong");
            });
          }
        });


    }
  },
   mounted: function() {

      $(window).data("EditUserModal",this);

          function ActivatePassword(){
      const togglePassword = document.querySelector("#togglePasswordedit");
      const password = document.querySelector("#passwordsigninedit");

      togglePassword.addEventListener("click", function () {
        
        // toggle the type attribute
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        // toggle the eye icon
        this.classList.toggle('fa-eye');
        this.classList.toggle('fa-eye-slash');
      });
    }

    ActivatePassword();

     $('#EditUserModal').on('show.bs.modal', function() {
        // do something when the modal is shown
          var context = $(window).data("EditUserModal");
          
          debugger;
          //context.GetAllBookings();

          analytics.page("Edit User Modal","EditUserModal");

           context.LoadData()
      });

  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

