/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import config from './configbase';

// options...
export default {
  // options...
  AUTH_URL: apiname => `${config.BASE_URL()}/authentication/${apiname}`,
  COMMUTER_URL: apiname => `${config.BASE_URL()}/Commuter/${apiname}`,
  FIN_COMMUTER_URL: apiname => `${config.BASEFIN_URL()}/Commuter/${apiname}`,
  FIN_AGENT_URL: apiname => `${config.BASEFIN_URL()}/Agent/${apiname}`,
  FIN_DEBITORDERS_URL: apiname => `${config.BASEFIN_URL()}/debitorder/${apiname}`,
  FIN_BANKCARDS_URL: apiname => `${config.BASEFIN_URL()}/BankCards/${apiname}`,
  FIN_TOPUPTRANS_URL: apiname => `${config.BASEFIN_URL()}/TransactionManager/TopUp/${apiname}`,
  FIN_TRANS_URL: apiname => `${config.BASEFIN_URL()}/TransactionManager/${apiname}`,
  LOOKUPS_URL: apiname => `${config.BASEFIN_URL()}/TPay/RedisSync/${apiname}`,
};


