<template>
 
 <div class="modal fade" id="TopUpDialog" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>

          <div class="modal-body">
         
            <div class="card" id="p">
              <div class="card-header">
                  <div class="bg-white shadow-sm pt-4 pl-2 pr-2 pb-2">
                      <!-- Credit card form tabs -->
                      <ul role="tablist" class="nav bg-light nav-pills rounded nav-fill mb-3">
                     <li class="nav-item"> <a data-toggle="pill" href="#credit-card" class="nav-link active"> <i class="fas fa-credit-card mr-2"></i> Card </a> </li>
                          <li class="nav-item"> <a data-toggle="pill" href="#DebitOrder" class="nav-link "> <i class="fas fa-calendar-check mr-2"></i> DebitOrder </a> </li>
                        <li class="nav-item"> <a data-toggle="pill" href="#Voucher" class="nav-link "> <i class="fas fa-receipt mr-2"></i> Voucher </a> </li>
                        
                        </ul>
                  </div> <!-- End -->

               
               
                  <!-- Credit card form content -->
                  <div class="tab-content">
                      <!-- credit card info-->
                      <div id="credit-card" class="tab-pane show active fade pt-3">

                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item" v-if="CARDS.length > 0">
                          <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Pay By Card
                            </button>
                          </h2>
                          <div v-if="CARDS.length > 0" :class="CARDS.length > 0 ? 'show' : ''" id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <div v-if="CARDS.length > 0" class="form-group "> <label for="Select Your Bank">
                                                <h6>Select Your Card</h6>
                                            </label> 
                                <select v-if="CARDS.length > 0" class="select" id="ccmonth"  v-model="SELECTEDCARDS">
                                                <option :key="option.bankcardsid" v-for="option in CARDS" v-bind:value="option">
                                                  {{ option.paymentbrand }} {{ option.cardno }} Exp: {{ option.expirymonth }}/{{ option.expiryyear }}
                                                </option>
                                            </select> </div>

                                    <div class="mb-0 position-relative d-flex flex-column">
                                  <label class="form-label text-secondary my-1"><small>Amount(R5)</small></label>
                                  <div class="mb-0 position-relative">
                                    <CurrencyInput
                                v-model="Amount"
                                :currency="currency"
                                :auto-decimal-mode="true"
                                class="form-control form-control shadow-none py-2"
                              />
                                  </div>
                                  <!-- /.btn-group btn-select-dropdown -->
                                </div>

                                <div class="form-group p-3">
                                                  <p> <button :disabled="this.$wait.is('LoggingIn') || Amount < 5 || !SELECTEDCARDS" v-if="CARDS.length > 0" @click="TopUp" type="button" class="btn btn-primary btn-lg me-4">
                                                  
                                                  <v-wait for="LoggingIn">
                                                      <template slot="waiting">
                                                        <i class="fas fa-spinner fa-spin"></i>
                                                      </template>
                                                      <i class="fas fa-mobile-alt "></i> 
                                                      TopUp Now
                                                    </v-wait>
                                                  </button> 
                                                  </p>
                                                    <div class="invoice-header">
                              <div v-show="TOPUPSUCCESS" class="alert alert-success alert-dismissible fade show">
                                  <span class="close" data-dismiss="alert">×</span>
                                  <strong class="alert-heading"> TopUp Of {{Amount | newcurrencywithSymbol}} Was Successfully.</strong>
                                </div>
                              </div>
                                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item" v-if="CARDS.length >= 0">
                          <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              Pay By New Card
                            </button>
                          </h2>
                          <div v-if="CARDS.length >= 0" :class="CARDS.length == 0 ? 'show' : ''" id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                            <div class="mb-0 position-relative d-flex flex-column">
                                  <label class="form-label text-secondary my-1"><small>Amount(R5)</small></label>
                                  <div class="mb-0 position-relative">
                                    <CurrencyInput
                                v-model="Amount"
                                :currency="currency"
                                :auto-decimal-mode="true"
                                class="form-control form-control shadow-none py-2"
                              />
                                  </div>
                                  <!-- /.btn-group btn-select-dropdown -->
                                </div>

                                <div class="form-group p-3">
                                                  <p> 
                                                    <button :disabled="this.$wait.is('LoggingIn') || Amount < 5" v-if="CARDS.length >= 0" type="submit" @click="payWithPaystack" class="subscribe btn btn-success btn-lg ">
                                                    <v-wait for="LoggingIn">
                                                      <template slot="waiting">
                                                        <i class="fas fa-spinner fa-spin"></i>
                                                      </template>
                                                      <i class="fas fa-credit-card mr-2"></i>
                                                        New Card/EFT
                                                    </v-wait>
                                                    </button>
                                                </p>
                                </div>
                                <div v-show="TOPUPSUCCESS" class="alert alert-success alert-dismissible fade show">
                                  <span class="close" data-dismiss="alert">×</span>
                                  <strong class="alert-heading"> TopUp Of {{Amount | newcurrencywithSymbol}} Was Successfully.!</strong>
                                </div>

                            </div>
                          </div>
                        </div>
                      </div>


                          <form role="form" onsubmit="event.preventDefault()">
                              <div class="card-footer"> 
                                 </div>
                                <p class="text-muted"> Note: After clicking on the button, you will be directed to a secure gateway for payment. After completing the payment process, you will be redirected back to the website to view details of your order. </p>
                            
                          </form>
                      </div>
                 
                  
                   

                    <!-- bank transfer info -->
                    <div id="Voucher" class="tab-pane fade pt-3">
                      <div class=""> 
                       
                        <div class="mb-0 position-relative d-flex flex-column">
                          <label class="form-label text-secondary my-1"><small>Voucher Pin</small></label>
                          <div class="mb-0 position-relative">
                            <input v-model="VoucherPIN" type="number" class="form-control form-control shadow-none py-2" />
                          </div>
                          <!-- /.btn-group btn-select-dropdown -->
                        </div>
                      <!-- /.mb-3 position-relative -->
                        
                      <div class="form-group p-3">
                          <p> <button :disabled="!CanSubmitVoucher || this.$wait.is('LoggingIn')" 
                          @click="PayByVoucher"
                          type="button" class="btn btn-primary btn-lg">
                             <v-wait for="LoggingIn">
                                <template slot="waiting">
                                 <i class="fas fa-spinner fa-spin"></i>
                                </template>
                                <i class="fas fa-receipt mr-2"></i>
                                  TopUp By Voucher
                              </v-wait>

                           </button> 
                           
                           
                           </p>

                           <div v-show="TOPUPSUCCESS" class="alert alert-success alert-dismissible fade show">
                                  <span class="close" data-dismiss="alert">×</span>
                                  <strong class="alert-heading"> TopUp Of {{Amount | newcurrencywithSymbol}} Was Successfully.</strong>
                                </div>
                      </div>
                      <p class="text-muted">Note: After clicking on the button, you will be directed to a secure gateway for payment. After completing the payment process, you will be redirected back to the website to view details of your order. </p>
                  </div> <!-- End -->
                  <!-- End -->
                 
                     </div>

                     <div id="DebitOrder" class="tab-pane fade pt-3">
                      <div class=""> 

                        <div class="accordion" id="accordionExample1">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne1" v-show="GETALLDEBITORDERS.length > 0">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
       All Debit Orders
      </button>
    </h2>
    <div v-show="GETALLDEBITORDERS.length > 0" :class="GETALLDEBITORDERS.length > 0 ? 'show' : ' '" id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
      <div class="accordion-body">
       <table id="data-table-combine-billpayments" class="table table-striped table-bordered data-table-combinedo">
              <thead>
                <tr>
                  <th class="text-nowrap">Account No</th>
                  <th class="text-nowrap">Frequency</th>
                  <th class="text-nowrap">Amount</th>
                  <th class="text-nowrap">Date</th>
                  <th class="text-nowrap"></th>
                  <!-- <th class="text-nowrap">Num Of Passangers</th>
                  <th class="text-nowrap">Total Fare Generated</th> -->
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item) in GETALLDEBITORDERS" :key="item.mandate_id">
                      <td>{{item.banks_name}}-{{item.accountnumber}} </td>
                      <td>{{item.recurringtype_name}}</td>
                      <td>{{item.recurringamount | newcurrencywithSymbol}}</td>
                      <td>{{item.start_date | formatToShortDate}}</td>
                      <td>
                        <button
                            type="button"
                            class="btn btn-sm btn-danger width-60 m-r-2"
                            @click="CancelDebitOrder(item)"
                            :disabled="$wait.is('LoggingIn-'+item.mandate_id)"
                          > 
                          <v-wait :for="'LoggingIn-'+item.mandate_id">
                                <template slot="waiting">
                                 <i class="fas fa-spinner fa-spin"></i>
                                </template>
                                <i class="fas fa-trash mr-2"></i>
                              </v-wait>
                          </button>
                          </td>
                    </tr>
              </tbody>
            </table>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo1">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1">
        Create Debit Order
      </button>
    </h2>
    <div :class="GETALLDEBITORDERS.length == 0 ? 'show' : ' '" id="collapseTwo1" class="accordion-collapse collapse" aria-labelledby="headingTwo1" data-bs-parent="#accordionExample1">
      <div class="accordion-body">

           <div v-if="GETFREQUENCY.length > 0" class="form-group my-1"> <label for="Select Your Frequency">
                          <h6>Select Frequency</h6>
                      </label> 
                      <select v-if="GETFREQUENCY.length > 0" class="select" id="ccmonth"  v-model="SELECTEDFREQUENCY">
                          <option :key="option.id" v-for="option in GETFREQUENCY" v-bind:value="option.code">
                             {{ option.name }} 
                          </option>
                      </select> 
                      </div>

                         <div v-if="GETBANKS.length > 0" class="form-group my-1"> <label for="Select Your Bank">
                          <h6>Select Bank</h6>
                      </label> 
                      <select v-if="GETBANKS.length > 0" class="select" id="ccmonth"  v-model="SELECTEDBANK">
                          <option :key="option.id" v-for="option in GETBANKS" v-bind:value="option">
                             {{ option.name }}
                          </option>
                      </select> 
                      </div>

                        <div v-if="GETACCOUNTYPES.length > 0" class="form-group my-1"> <label for="Select Your Account Type">
                          <h6>Select Account Type</h6>
                      </label> 
                      <select v-if="GETACCOUNTYPES.length > 0" class="select" id="ccmonth"  v-model="SELECTEDACCOUNTYPE">
                          <option :key="option.id" v-for="option in GETACCOUNTYPES" v-bind:value="option.code">
                             {{ option.name }} 
                          </option>
                      </select> 
                      </div>

                        <div class="mb-0 position-relative d-flex flex-column">
                        <label class="form-label text-secondary my-1"><small>Debit Order Date</small></label>
                        <div class="mb-0 position-relative">
                          <input type="text" class="form-control form-control shadow-none py-2" placeholder="Select Date" name="debitorderdate" />
                        </div>
                        <!-- /.btn-group btn-select-dropdown -->
                      </div>
                    <!-- /.mb-3 position-relative -->

                        <div class="mb-0 position-relative d-flex flex-column">
                          <label class="form-label text-secondary my-1"><small>Account Number</small></label>
                          <div class="mb-0 position-relative">
                            <input type="number" v-model="AccountNumber" class="form-control form-control shadow-none py-2" />
                          </div>
                          <!-- /.btn-group btn-select-dropdown -->
                        </div>
                      <!-- /.mb-3 position-relative -->

                       <div class="mb-0 position-relative d-flex flex-column">
                          <label class="form-label text-secondary my-1"><small>ID Number</small></label>
                          <div class="mb-0 position-relative">
                            <input type="number" v-model="IDNumber" class="form-control form-control shadow-none py-2" />
                          </div>
                          <!-- /.btn-group btn-select-dropdown -->
                        </div>
                      <!-- /.mb-3 position-relative -->

                      <div class="mb-0 position-relative d-flex flex-column">
                          <label class="form-label text-secondary my-1"><small>Amount {{STARTDATE}} {{COLLECTIONDAY}}</small></label>
                          <div class="mb-0 position-relative">
                            <input type="number" v-model="Amount" class="form-control form-control shadow-none py-2" />
                          </div>
                          <!-- /.btn-group btn-select-dropdown -->
                          
                        </div>
                      <!-- /.mb-3 position-relative -->

                      
                      
                        
                      <div class="form-group my-3">
                        <p> <button :disabled="!CanSubmitDebitOrder || this.$wait.is('LoggingIn')" 
                          @click="PayByDebitOrder"
                          type="button" class="btn btn-primary btn-lg">
                             <v-wait for="LoggingIn">
                                <template slot="waiting">
                                 <i class="fas fa-spinner fa-spin"></i>
                                </template>
                                <i class="fas fa-calendar-check mr-0"></i>
                                  Create Debit Order
                              </v-wait>

                           </button> </p>
                        </div>

                         <div class="invoice-header">
                              <div v-show="TOPUPSUCCESSDEBITORDER" class="alert alert-success alert-dismissible fade show">
                                  <span class="close" data-dismiss="alert">×</span>
                                  <strong class="alert-heading"> Debit Order Created Successfully.</strong>
                                </div>
                              </div>


                      </div>
                    </div>
                  </div>
                </div>



             
                  </div> <!-- End -->
                  <!-- End -->
                 
                     </div>
                      </div> <!-- End -->
                    </div>
                     
          </div>
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- modal-dialog modal-dialog-centered -->
    </div>
  </div>
    <!-- /.modal -->  

</template>

<script>


import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('commuter');

export default {
	 name: "TopUpDialog",
	 props:['AddTopSearchControl'],
  data: function() {
    return {
      GETALLDEBITORDERS:[],
      SELECTEDCARDS: undefined,
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      WALLETPROFILE: this.$store.getters["auth/WALLETPROFILE"],
      Amount: 0,
      TOPUPSUCCESS: false,
      TOPUPSUCCESSDEBITORDER: false,
      VoucherPIN: 0,
      SELECTEDFREQUENCY: undefined,
      SELECTEDBANK: undefined,
      SELECTEDACCOUNTYPE: undefined,
      AccountNumber: '',
      IDNumber: '',
      COLLECTIONDAY:undefined,
      STARTDATE:undefined,
      COLLECTIONDAYS : [{
        code: '01'},{code: '02'},{code: '03'},{code: '04'},{code: '05'},{code: '06'},{code: '07'},{code: '08'},{code: '09'},{
        code: '10'},{code: '11'},{code: '12'},{code: '13'},{code: '14'},{code: '15'},{code: '16'},{code: '17'},{code: '18'},{code: '19'},{
        code: '20'},{code: '21'},{code: '22'},{code: '23'},{code: '24'},{code: '25'},{code: '26'},{code: '27'},{code: '28'},{code: '29'},{
        code: '30'},
      ],
    };
  },
  computed: {
  ...mapGetters({CARDS:"GETCARDS",GETFREQUENCY:"GETFREQUENCY",GETBANKS:"GETBANKS",GETACCOUNTYPES:"GETACCOUNTYPES"}),
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    CanSubmitVoucher: function (){
      return this.VoucherPIN != "";
    },
     CanSubmitDebitOrder: function (){
      return this.SELECTEDFREQUENCY != "" && this.SELECTEDBANK != undefined && this.SELECTEDACCOUNTYPE != null &&
      this.SELECTEDACCOUNTYPE != "" && this.AccountNumber != '' && this.IDNumber != '' && this.COLLECTIONDAY !== '' &&
      this.STARTDATE !== '' && parseFloat(this.Amount) > 0;
    }
  },
  components: {
  },
  methods: {
     ...mapActions([
      "GetBankCardsAction",
      "TopUpWalletAction",
      "AddBankCardAndCharge",
    ]),
    LoadData: function ()
    {
      if(this.USERPROFILE != null && this.USERPROFILE != undefined && this.USERPROFILE.hasOwnProperty('idnumber') && this.USERPROFILE.idnumber != undefined) {
        this.IDNumber = this.USERPROFILE.idnumber;
      }
    },
    GetAllDebitOrdersFn: function ()
    {
      var context = $(window).data("TopUpDialog");
      context.$store.dispatch("commuter/GetDebitOrdersAction").then((results)=>{
         if(results.Status){
           debugger;
         var options = {
                    buttons: [
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: true,
                    rowReorder: false,
                    select: false,
                    paging:   false,
                    ordering: false,
                    info:     false
                  };
         
         //var context = $(window).data("contextdebitorder");
         if( results.Data != undefined && results.Data != null)
          this.GETALLDEBITORDERS = results.Data;
          //$(".data-table-combinedo").DataTable(options)
         } else {
           //this.$Notify.Error("TopUp Failed",results.Message);
         }
     }).catch((error)=>{
        this.$Notify.Error("TopUp Failed",error);
     });
    },
    CancelDebitOrder: function (item)
    {

       var context = $(window).data("TopUpDialog");

       analytics.track("Cancel Debit Order", item);

      context.$Notify
        .YesNo(false,true)
        .then((value) => {
          if (value) {
            context.$wait.start("LoggingIn-"+item.mandate_id);

          context.$store
        .dispatch("commuter/CancelDebitOrderAction",{
         contractReference:item.contractreference,
         clientReference:item.clientreference,
         mandateID:item.mandate_id,
       }).then((results)=>{
         if(results.Status){
          this.$store
          .dispatch("auth/GetProfile");
          this.GetAllDebitOrdersFn();
          this.$Notify.Success("Successfully","Debit Order Cancelled Successfully");
         } else {
           this.$Notify.Error("Failed To Cancel Debit Order",results.Message);
         }
         
     }).catch(()=>{
      context.$Notify.Error("Cancel Debit Order","Error Cancelling Debit Order Cancelled");
     }).finally(()=>{
       context.$wait.end("LoggingIn-"+item.mandate_id);
       
     });
            
          }
        });

    },
    PayByDebitOrder: function ()
    {
      //alert(this.SELECTEDCARDS.token);

      this.$wait.start("LoggingIn");

      if(this.IDNumber.length == 0) {
        $('#EditUserModal').modal('show')
      }

      this.TOPUPSUCCESSDEBITORDER = false;

       debugger

        analytics.track("Pay By DebitOrder", {
         Frequency:this.SELECTEDFREQUENCY,
         CollectionDay:this.COLLECTIONDAY,
         DebtorId:this.IDNumber,
         DebtorAccountNumber:this.AccountNumber,
         DebtorAccountType:this.SELECTEDACCOUNTYPE,
         DebtorBankId:this.SELECTEDBANK.id,
         DebtorBranchNumber:this.SELECTEDBANK.universalbranchcode,
         CollectionAmount:this.Amount,
         StartDate:this.STARTDATE,
       });

       this.$store
        .dispatch("commuter/TopUpByDebitOrderAction",{
         Frequency:this.SELECTEDFREQUENCY,
         CollectionDay:this.COLLECTIONDAY,
         DebtorId:this.IDNumber,
         DebtorAccountNumber:this.AccountNumber,
         DebtorAccountType:this.SELECTEDACCOUNTYPE,
         DebtorBankId:this.SELECTEDBANK.id,
         DebtorBranchNumber:this.SELECTEDBANK.universalbranchcode,
         CollectionAmount:this.Amount,
         StartDate:this.STARTDATE,
       }).then((results)=>{
         if(results.Status){
        this.$store
        .dispatch("auth/GetProfile");
         this.TOPUPSUCCESSDEBITORDER = true;
         this.Amount = 0;
         this.AccountNumber = 0;
         this.IDNumber = "";
         this.STARTDATE = "";
         this.GetAllDebitOrdersFn();
         } else {
           this.$Notify.Error(results.Data.ErrorMessage[0].ErrorMessage,"");
         }
         
     }).catch((results)=>{
        this.$Notify.Error(results.Data.ErrorMessage[0].ErrorMessage,"");
     }).finally(()=>{
       this.$wait.end("LoggingIn");
     });

    },
    PayByVoucher: function ()
    {
      //alert(this.SELECTEDCARDS.token);

      this.$wait.start("LoggingIn");

       this.TOPUPSUCCESS = false;

       analytics.track("Pay By Voucher", {
         voucherpin:this.VoucherPIN,
       });

       this.$store
        .dispatch("commuter/TopUpByVoucherAction",{
         voucherpin:this.VoucherPIN,
       }).then((results)=>{
         if(results.Status){
        this.$store
        .dispatch("auth/GetProfile");

        this.Amount = results.Data.Amount
        this.VoucherPIN = "";

         this.TOPUPSUCCESS = true;
         } else {
           this.$Notify.Error("TopUp Failed",results.Message);
         }
         
     }).catch(()=>{
     }).finally(()=>{
       this.$wait.end("LoggingIn");
     });

    },
    TopUp: function ()
    {
      //alert(this.SELECTEDCARDS.token);

      this.$wait.start("LoggingIn");

       this.TOPUPSUCCESS = false;

       this.$store
        .dispatch("commuter/TopUpWalletAction",{
         BankCardId:this.SELECTEDCARDS.bankcardsid,
         CardNo:this.SELECTEDCARDS.token,
         Amount:this.Amount,
       }).then((results)=>{
         if(results.Status){
        this.$store
        .dispatch("auth/GetProfile");
         this.TOPUPSUCCESS = true;
         } else {
           this.$Notify.Error("TopUp Failed",results.Message);
         }
         
     }).catch(()=>{
     }).finally(()=>{
       this.$wait.end("LoggingIn");
     });

    },
    payWithPaystack: function (){
        //e.preventDefault();

  $(window).data("TopUpDialog",this);

  var urlParams = new URLSearchParams(window.location.search);
	//  userid = urlParams.get('id')
	//  email = urlParams.get('email')
	//  mobile = urlParams.get('mobile')

  var userid = this.USERPROFILE.SystemUsersId;
	 var email =  this.WALLETPROFILE.email;
	 var mobile = this.USERPROFILE.MobileNumber;
   var topupamount = this.Amount;

  let handler = PaystackPop.setup({
    key: 'pk_live_a1869bc457449b0aa329202e83905961d960a6af', // Replace with your public key
    email: email,
    amount: topupamount * 100,
	  currency: "ZAR",
    ref: ''+Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
    // label: "Optional string that replaces customer email"
    onClose: function(){
      alert('Window closed.');
    },
    callback: function(response){
      let message = 'Payment complete! Reference: ' + response.reference;
      //alert(message);

	  //$('.subscribe').text('Refunding R5...')


     var context = $(window).data("TopUpDialog");

     context.$wait.start("LoggingIn");

      context.TOPUPSUCCESS = false;

      analytics.track("TopUp Via New Card", {
        transaction: response.transaction,
        reference : response.reference,
        response: response,
       });

      context.$store
        .dispatch("commuter/AddBankCardAndCharge",{
        transaction: response.transaction,
        reference : response.reference,
        response: response,
       }).then((results)=>{
         debugger
          var context = $(window).data("TopUpDialog");
         if(results.Status){
          context.$store
          .dispatch("auth/GetProfile");
          context.TOPUPSUCCESS = true;
         } else {
           context.$Notify.Error("TopUp Failed",results.Message);
         }
     }).catch((err)=>{
        debugger
        alert(JSON.stringify(err));
     }).finally(()=>{
       context.$wait.end("LoggingIn");
     });

    }
  });
  handler.openIframe();
    }
  },
   mounted: function() {


    $(window).data("TopUpDialog",this);

       $('input[name="debitorderdate"]').daterangepicker({
      singleDatePicker: true,
      showDropdowns: false,
      minYear: 1901,
      minDate: moment(),
      maxYear: parseInt(moment().format('YYYY'),10)
    }, function(start, end, label) {

      debugger;
      var context = $(window).data("TopUpDialog");
      context.COLLECTIONDAY = start.format('DD');
      context.STARTDATE = start.format('YYYY-MM-DD');
      
    });


      //$(window).data("topupdialogcontext",this);
        

        $('#TopUpDialog').on('show.bs.modal', function() {
        // do something when the modal is shown
          var context = $(window).data("TopUpDialog");

          context.$store.dispatch("commuter/DebitOrderFrequencyAction");
          context.$store.dispatch("commuter/BanksAction");
          context.$store.dispatch("commuter/BankAccountTypeAction");
          
          debugger;
          context.GetAllDebitOrdersFn();

          context.LoadData();

          analytics.page("Top Up Dialog","TopUpDialog");
      });




    //  this.$store
    //     .dispatch("auth/GetProfile");


     //alert(this.USERPROFILE.SystemUsersId);

      
     
    //  const contexttemp = this;
    //  this.$store.dispatch("TA/getLOGOURL", {
    //   id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    // }).then((result) => {

    //   //debugger;
      
    //   contexttemp.LOGO = result;
    // }).catch((err) => {
    // });
  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

