<template>
 
   <div class="modal fade" id="RentATaxiModals" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>

          <div class="modal-body">
         <div class="text-center text-uppercase fs-6 fw-bolder timeheader">Booking expires in <span class="w-bolder text-danger time" >05:00</span> minutes!</div>
                          
            <div class="container">
              <div class="card">
                  <div class="form">
                      <div class="left-side">
                          <div class="left-heading">
                              <h3>Rent A Taxi</h3>
                          </div>
                          <div class="steps-content">
                              <h3>Step <span class="step-number">1</span></h3>
                              <p class="step-number-content_rent active">Details Of Bookings.</p>
                              <p class="step-number-content_rent d-none">Choose Method Of Payment.</p>
                              <p class="step-number-content_rent d-none">Confirmation Of Booking.</p>
                          </div>
                          <ul class="progress-bar">
                              <li class="active">Summary</li>
                              <li>Payment</li>
                              <li>Confirmation</li>
                          </ul>
              
                      </div>
                      <div class="right-side">
                          <div class="main active">
                               <div class="bg-pay p-2"> 
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Departure Date</span> <span>{{GETRENTALDETAILS.DepartureDateText}}</span> </div>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Return Date</span> <span>{{GETRENTALDETAILS.ReturnDateText}}</span> </div>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Vehicle Type</span> <span v-if="GETSELECTEDRENTAL">{{GETSELECTEDRENTAL.name}}</span> </div>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">From</span> <span>{{GETRENTALDETAILS.From.split(',')[0]}}</span> </div>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">To</span> <span>{{GETRENTALDETAILS.To.split(',')[0]}}</span> </div>
                              <div class="d-flex justify-content-between mt-2 form-group" > <span class="fw-500">Booking Reference</span> <span><input type="text" class="form-control shadow-none input-sm" v-model="BOOKINGREF" placeholder="Enter Booking Ref"/></span> </div>
                              <div class="d-flex justify-content-between mt-2 form-group" > <span class="fw-500">Special Requirements</span> <span>
                               <textarea id="txtid" name="txtname" rows="2" cols="50" maxlength="200" v-model="BOOKINGCOMMENT">
                              
                                </textarea>
                              </span> 
                              </div>
                               
                              <hr>
                              <div v-if="GETRENTALDETAILS.Trailer" class="d-flex justify-content-between mt-2"> <span class="fw-500">Trailer</span><span class="o-50 me-2" v-if="GETSELECTEDRENTAL">{{GETSELECTEDRENTAL.trailerprice | newcurrencywithSymbol}} p/d</span> <span>{{TRAILERPRICE | newcurrencywithSymbol}}</span> </div>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Sub Total</span><span class="o-50 me-2" style="text-decoration: line-through;" v-if="GETSELECTEDRENTAL">{{GETSELECTEDRENTAL.StrikePrice | newcurrencywithSymbol}}</span> <span v-if="GETSELECTEDRENTAL">{{GETSELECTEDRENTAL.SubTotal | newcurrencywithSymbol}}</span> </div>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Booking Fee</span><span class="o-50 me-2" style="text-decoration: line-through;">{{BOOKINGFEEBEFORE | newcurrencywithSymbol}}</span> <span>{{BOOKINGFEE | newcurrencywithSymbol}}</span>  </div>
                              <hr>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Total </span><span class="me-2 text-success" >Discount {{DISCOUNT | newcurrencywithSymbol}}</span> <span class="text-success">{{TOTAL | newcurrencywithSymbol}}</span> </div>
                            </div>
                            <div class="buttons">
                              <button class="next_button_rent">Next</button>
                          </div>
                          </div>
                          <div class="main">
                                         

<ul class="nav nav-pills nav-fill bg-light rounded">
   <li class="nav-item"> <a data-toggle="pill" href="#rentWallet" class="nav-link active"> <i class="fas fa-wallet mr-2"></i> Wallet </a> </li>
  <li class="nav-item"> <a data-toggle="pill" href="#rentcard" class="nav-link "> <i class="fas fa-credit-card mr-2"></i> Card & EFT </a> </li>

</ul>

<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="rentWallet" role="tabpanel" aria-labelledby="home-tab">
      <div class="form-group py-6">
                          <p class="bottom-100"> <button 
                          @click="SubmitBooking('ACT','TPW')"
                          :disabled="this.$wait.is('LoggingIn')" 
                          
                          type="button" class="btn btn-success btn-lg">
                             <v-wait for="LoggingIn">
                                <template slot="waiting">
                                 <i class="fas fa-spinner fa-spin"></i>
                                </template>
                                <i class="fas fa-wallet mr-2"></i>
                                  Pay Now <span>{{Amount | newcurrencywithSymbol}} </span>
                              </v-wait>

                           </button> </p>
                      </div>
                      <p class="text-muted">Note: After clicking on the button, you will be directed to a secure gateway for payment. After completing the payment process, you will be redirected back to the website to view details of your order. </p>
                  
  </div>
  <div class="tab-pane fade" id="rentcard" role="tabpanel" aria-labelledby="profile-tab">
     
<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item" v-if="CARDS.length > 0">
    <h2 class="accordion-header" id="flush-headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        Pay By Card
      </button>
    </h2>
    <div id="flush-collapseOne" :class="CARDS.length > 0 ? 'show' : 'dontshow'" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <div v-if="CARDS.length > 0" class="form-group mb-1"> <label for="Select Your Bank">
                                                <h6>Select Your Card</h6>
                                            </label> 
                                <select v-if="CARDS.length > 0" class="select" id="ccmonth"  v-model="SELECTEDCARDS">
                                                <option :key="option.bankcardsid" v-for="option in CARDS" v-bind:value="option">
                                                  {{ option.paymentbrand }} {{ option.cardno }} Exp: {{ option.expirymonth }}/{{ option.expiryyear }}
                                                </option>
                                            </select> </div>

                                    <div class="mb-0 position-relative d-flex flex-column">
                                       <h6 class="text-secondary my-1">Amount(R5)</h6>
                                  <div class="mb-1 position-relative">
                                    <CurrencyInput
                                v-model="Amount"
                                :currency="currency"
                                :auto-decimal-mode="true"
                                class="form-control form-control shadow-none py-2"
                              />
                                  </div>
                                  <!-- /.btn-group btn-select-dropdown -->
                                </div>

                                <div class="form-group p-3">
                                                  <p> <button :disabled="this.$wait.is('LoggingIn')" @click="TopUp" type="button" class="btn btn-primary btn-lg me-4">
                                                  
                                                  <v-wait for="LoggingIn">
                                                      <template slot="waiting">
                                                        <i class="fas fa-spinner fa-spin"></i>
                                                      </template>
                                                      <i class="fas fa-mobile-alt "></i> 
                                                      TopUp Now
                                                    </v-wait>
                                                  </button> 
                                                  </p>
                                                    <div class="invoice-header">
                              <div v-show="TOPUPSUCCESS" class="alert alert-success alert-dismissible fade show">
                                  <span class="close" data-dismiss="alert">×</span>
                                  <strong class="alert-heading"> TopUp Of {{Amount | newcurrencywithSymbol}} Was Successfully.</strong>
                                </div>
                              </div>
                                              </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
       Pay By New Card
      </button>
    </h2>
    <div id="flush-collapseTwo" :class="CARDS.length == 0 ? 'show' : 'dontshow'" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <div class="mb-0 position-relative d-flex flex-column">
                                  <h6 class="text-secondary my-1">Amount</h6>
                                  <div class="mb-0 position-relative">
                                    <CurrencyInput
                                v-model="Amount"
                                :currency="currency"
                                :auto-decimal-mode="true"
                                class="form-control form-control shadow-none py-2"
                              />
                                  </div>
                                  <!-- /.btn-group btn-select-dropdown -->
                                </div>

                                <div class="form-group p-3">
                                                  <p> 
                                                    <button :disabled="this.$wait.is('LoggingIn') || Amount < 5" v-if="CARDS.length >= 0" type="submit" @click="payWithPaystack" class="subscribe btn btn-success btn-lg ">
                                                    <v-wait for="LoggingIn">
                                                      <template slot="waiting">
                                                        <i class="fas fa-spinner fa-spin"></i>
                                                      </template>
                                                      <i class="fas fa-credit-card mr-2"></i>
                                                        Pay With New Card/EFT
                                                    </v-wait>
                                                    </button>
                                                </p>
                                </div>
                                <div v-show="TOPUPSUCCESS" class="alert alert-success alert-dismissible fade show">
                                  <span class="close" data-dismiss="alert">×</span>
                                  <strong class="alert-heading"> TopUp Of {{Amount | newcurrencywithSymbol}} Was Successfully.!</strong>
                                </div>

      </div>
    </div>
  </div>
</div>

  </div>
</div>


                              <div class="buttons button_space ">
                                  <button class="back_button_rent back_button33">Back</button>
                                  <button class="next_button_rent next_button33 hide">Next Step</button>
                              </div>
                          </div>
                          <div class="main">
                            <div class="bg-pay p-3"> 
                              <table id="data-table-combine-submitrental" class="table table-striped table-bordered data-table-combine data-table-combine-submitbooking">
              <thead>
                <tr>
                  <th class="text-nowrap">Departure Date</th>
                  <th class="text-nowrap">Return Date</th>
                  <th class="text-nowrap">Booking No</th>
                  <th class="text-nowrap">Rental Price</th>
                  <th class="text-nowrap">Status</th>
                  <!-- <th class="text-nowrap">Num Of Passangers</th>
                  <th class="text-nowrap">Total Fare Generated</th> -->
                </tr>
              </thead>

               <tbody>
                <tr v-for="(item) in RENTALS" :key="item.TransactionsId">
                      <td>{{GETRENTALDETAILS.DepartureDateText}} </td>
                      <td>{{GETRENTALDETAILS.ReturnDateText}} </td>
                      <td>{{item.BookingNo}} </td>
                      <td>{{TOTAL | newcurrencywithSymbol}}</td>
                      <td>
                        <span v-if="item.Status == 'Accepted'" class="btn-success badge">{{item.Status}}</span>
                        <span v-if="item.Status == 'CheckIn'" class="badge btn-success">{{item.Status}}</span>
                        <span v-if="item.Status == 'Cancelled'" class="badge btn-danger">{{item.Status}}</span>
                        <span v-if="item.Status == 'Waiting List'" class="badge btn-warning">{{item.Status}}</span>
                        <span v-if="item.Status == 'Pending'" class="badge btn-warning">{{item.Status}}</span>
                        <span v-if="item.Status == 'Revoked'" class="badge btn-danger">{{item.Status}}</span>
                        <span v-if="item.Status == 'Pay Later'" class="badge btn-warning">{{item.Status}}</span>
                      </td>
                    </tr>
              </tbody>
            </table>
                           </div>
                           <div class="alert alert-success alert-dismissible fade show">
                                  <span class="close" data-dismiss="alert">×</span>
                                  <strong class="alert-heading"> Congratulations, Booking Was Successful!</strong>
                                </div>
                          <div class="buttons hide">
                            <button class="next_button_rent">Done</button>
                          </div>
                        </div>
                        
                      </div>
                  </div>
              </div>
          </div>

          </div>
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- modal-dialog modal-dialog-centered -->
    </div>
    <!-- /.modal -->

</template>

<script>


import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('commuter');

export default {
	 name: "RentATaxiModals",
	 props:[],
  data: function() {
    return {
      SELECTEDCARDS: undefined,
      TOPUPSUCCESS: false,
      RENTALS:[],
      SUBMITTED: false,
      BOOKINGREF:'',
      BOOKINGCOMMENT:'',
    };
  },
  computed: {
    ...mapGetters({CARDS:"GETCARDS",GETBOOKINGDETAILS:"GETBOOKINGDETAILS",USERPROFILE:"USERPROFILE",
    WALLETPROFILE:"WALLETPROFILE",GETRENTALDETAILS:"GETRENTALDETAILS",GETSELECTEDRENTAL:"GETSELECTEDRENTAL"}),
    BAL: function () {
      // `this` points to the vm instance
      try {
        
        return this.WALLETPROFILE.account.balance;
      } catch (e) {
        return 0;
      }
    },
    Amount: function () {
      // `this` points to the vm instance
      try { 
        return this.BOOKINGFEE + this.TRAILERPRICE + this.GETSELECTEDRENTAL.SubTotal;
      } catch (e) {
        return 0;
      }
    },
    TRAILERPRICE: function () {
      // `this` points to the vm instance
      try {

        if(this.GETRENTALDETAILS.Trailer)
        {
          return this.GETSELECTEDRENTAL.trailerprice * this.GETRENTALDETAILS.DaysTravel
        } else {
          return 0;
        }
      } catch (e) {
        return 0;
      }
    },
    STRIKEPRICE: function () {
      // `this` points to the vm instance
      try {
        
        return this.WALLETPROFILE.account.balance + this.TRAILERPRICE;
      } catch (e) {
        return 0;
      }
    },
    BOOKINGFEEBEFORE: function () {
      // `this` points to the vm instance
      try {
        
        return (this.GETSELECTEDRENTAL.SubTotal * (this.GETRENTALDETAILS.RentalFee+15)/100)
      } catch (e) {
        return 0;
      }
    },
    TOTAL: function () {
      // `this` points to the vm instance
      try {

        return this.BOOKINGFEE + this.GETSELECTEDRENTAL.SubTotal + this.TRAILERPRICE;
      } catch (e) {
        return 0;
      }
    },
    DISCOUNT: function () {
      // `this` points to the vm instance
      try {
        
        return (this.BOOKINGFEEBEFORE + this.GETSELECTEDRENTAL.StrikePrice) - this.TOTAL
      } catch (e) {
        return 0;
      }
    },
    BOOKINGFEE: function () {
      // `this` points to the vm instance
      try {
        
        return (this.GETSELECTEDRENTAL.SubTotal * (this.GETRENTALDETAILS.RentalFee)/100)
      } catch (e) {
        return 0;
      }
    },
    currency() {
      return { [this.position]: this.currencySymbol };
    },
  },
  components: {
  },
  methods: {
    SubmitBooking: function (BookingStatusCode,
    TransactionSourceCode)
    {
      //alert(this.SELECTEDCARDS.token);

      //$('.next_button2').click();

     this.$wait.start("LoggingIn");

    //    this.TOPUPSUCCESS = false;

    analytics.track("Submit Rent A Taxi Booking", {
          VehicleTypeCode: this.GETSELECTEDRENTAL.code,
          VehicleTypeDesc: this.GETSELECTEDRENTAL.description,
          DepartureDate: this.GETRENTALDETAILS.DepartureDate,
          ReturnDate: this.GETRENTALDETAILS.ReturnDate,
          FromAddress: this.GETRENTALDETAILS.From,
          FromAddresslocation: '',
          ToAddress: this.GETRENTALDETAILS.To,
          BookingRef:this.BOOKINGREF,
          BookingComment:this.BOOKINGCOMMENT,
          ToAddresslocation: '',
          DistanceInKm: this.GETRENTALDETAILS.Distance,
          Trailer: this.TRAILERPRICE,
          AmountPaid: this.TOTAL,
          Fee: this.BOOKINGFEE,
          BookingStatusCode: BookingStatusCode,
          TransSourceCode: TransactionSourceCode,
          SendNotification:true,
          SendNotification:true,
       });

    

       this.$store
        .dispatch("commuter/SubmitRentATaxiAction",{
          VehicleTypeCode: this.GETSELECTEDRENTAL.code,
          VehicleTypeDesc: this.GETSELECTEDRENTAL.description,
          DepartureDate: this.GETRENTALDETAILS.DepartureDate,
          ReturnDate: this.GETRENTALDETAILS.ReturnDate,
          FromAddress: this.GETRENTALDETAILS.From,
          FromAddresslocation: '',
          ToAddress: this.GETRENTALDETAILS.To,
          ToAddresslocation: '',
          DistanceInKm: this.GETRENTALDETAILS.Distance,
          AmountPaid: this.TOTAL,
          Fee: this.BOOKINGFEE,
          BookingStatusCode: BookingStatusCode,
          TransSourceCode: TransactionSourceCode,
          SendNotification:true,
          BookingRef: this.BOOKINGREF,
          BookingComment:this.BOOKINGCOMMENT,
       }).then((results)=>{
         if(results.Status){
           debugger
           this.RENTALS = [results.Data];
        this.$store
        .dispatch("auth/GetProfile");
         $('.next_button33').click();
        
         this.SUBMITTED = true;

         EndCount();

         } else {
           this.$Notify.Error("TopUp Failed",results.Message);
         }
         
     }).catch(()=>{
     }).finally(()=>{
       this.$wait.end("LoggingIn");
       var options = {
                    buttons: [
                    ],
                    responsive: true,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false,
                    paging:   false,
                    ordering: false,
                    info:     false
                  };

                  //$(".data-table-combine-submitrental").DataTable(options)
     });

    },
    TopUp: function ()
    {
      //alert(this.SELECTEDCARDS.token);

      var context = $(window).data("RentATaxiModals");

      context.$wait.start("LoggingIn");

       context.TOPUPSUCCESS = false;

       analytics.track("TopUp Via Card", {
         BankCardId:this.SELECTEDCARDS.bankcardsid,
         CardNo:this.SELECTEDCARDS.token,
         Amount:this.Amount*100,
       });

       this.$store
        .dispatch("commuter/TopUpWalletAction",{
         BankCardId:this.SELECTEDCARDS.bankcardsid,
         CardNo:this.SELECTEDCARDS.token,
         Amount:this.Amount*100,
       }).then((results)=>{
         if(results.Status){
      
         context.TOPUPSUCCESS = true;
         context.SubmitBooking('ACT','DC');
         } else {
           context.$Notify.Error(results.Message);
         }
         
     }).catch(()=>{
     }).finally(()=>{
       context.$wait.end("LoggingIn");
     });

    },
    payWithPaystack: function (){
        //e.preventDefault();

  //$(window).data("RentATaxiModals",this);

  var urlParams = new URLSearchParams(window.location.search);
	//  userid = urlParams.get('id')
	//  email = urlParams.get('email')
	//  mobile = urlParams.get('mobile')

  var userid = this.USERPROFILE.SystemUsersId;
	 var email =  this.WALLETPROFILE.email;
	 var mobile = this.USERPROFILE.MobileNumber;
   var topupamount = this.Amount;

  let handler = PaystackPop.setup({
    key: 'pk_live_a1869bc457449b0aa329202e83905961d960a6af', // Replace with your public key
    email: email,
    amount: topupamount * 100,
	  currency: "ZAR",
    ref: ''+Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
    // label: "Optional string that replaces customer email"
    onClose: function(){
      alert('Window closed.');
    },
    callback: function(response){
      let message = 'Payment complete! Reference: ' + response.reference;
      //alert(message);

	  //$('.subscribe').text('Refunding R5...')


     var context = $(window).data("RentATaxiModals");

     context.$wait.start("LoggingIn");

      context.TOPUPSUCCESS = false;

      analytics.track("TopUp Via New Card", {
        transaction: response.transaction,
        reference : response.reference,
        response: response,
       });

      context.$store
        .dispatch("commuter/AddBankCardAndCharge",{
        transaction: response.transaction,
        reference : response.reference,
        response: response,
       }).then((results)=>{
         
         if(results.Status){
          context.TOPUPSUCCESS = true;
          context.SubmitBooking('ACT','DC');
         } else {
           context.$Notify.Error(results.Message);
         }
     }).catch((err)=>{
        
        alert(JSON.stringify(err));
     }).finally(()=>{
       context.$wait.end("LoggingIn");
     });

    }
    });
    handler.openIframe();
    },
  },
   mounted: function() {

     $(window).data("RentATaxiModals",this);

      $('#RentATaxiModals').on('show.bs.modal', function() {

        // do something when the modal is shown
        var context = $(window).data("RentATaxiModals");
       
        // if(context.SUBMITTED){
        //   context.SUBMITTED = false;

        // formnumber=1;
        // progress_backward();

          //$('.back_button_rent').click();
          //$('.back_button_rent').click();
        // } else {
        //   //context.Amount = context.GETBOOKINGDETAILS.Total;
        // }

        //this.GETBOOKINGDETAILS.DepartureId
        StartCount(10);

        analytics.page("Rent A Taxi Modals","RentATaxiModals");

     });

     $('#RentATaxiModals').on('hidden.bs.modal', function (e) {
      $('#SharedWithSocials').modal('show')
     })


     



// var main_form=document.querySelectorAll(".main");
 var main_form=document.querySelectorAll("#RentATaxiModals .main");
var step_list = document.querySelectorAll(".progress-bar li");
var num = document.querySelector(".step-number");
let formnumber=0;

    $('.next_button_rent','#RentATaxiModals').on('click',()=>{
          formnumber++;
          //alert("next_click_form");
          updateform();
          progress_forward();
          contentchange();
    });

      $('.back_button_rent','#RentATaxiModals').on('click',()=>{
       formnumber--;
       updateform();
       progress_backward();
       contentchange();
     });

 

function updateform(){
    main_form.forEach(function(mainform_number){
        mainform_number.classList.remove('active');
    })
    main_form[formnumber].classList.add('active');
} 
 
function progress_forward(){     
   
    num.innerHTML = formnumber+1;
    step_list[formnumber].classList.add('active');
}  

function progress_backward(){
    var form_num = formnumber+1;
    step_list[form_num].classList.remove('active');
    num.innerHTML = form_num;
} 
 
var step_num_content=document.querySelectorAll(".step-number-content_rent");

 function contentchange(){
     step_num_content.forEach(function(content){
   
        content.classList.remove('active'); 
        content.classList.add('d-none');
     }); 

     step_num_content[formnumber].classList.add('active');
 } 
 
  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

