<template>
  <div id="app">

    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "App",
  data() {
    return {};
  },

  components: {},
  methods: {},
  computed: {
    verifyreCaptureToken(token) {}
  },
  mounted: function() {
    window.onpopstate = event => {
      if (
        window.localStorage.getItem("info") !== null &&
        this.$route.path == "/"
      ) {
          console.log('est1');
        this.$router.push("login"); // redirect to home, for example
      }
    };

    // `this` points to the vm instance

    /*grecaptcha.ready(function () {
            grecaptcha.execute('6LddYYgUAAAAADPyMA31jzCV2gZi9FkomQhNufqy', { action: 'loginpage' })
                .then(function (token) {
                    // Verify the token on the server.
                    //this.verifyreCaptureToken(token);
                    console.log(token);

                });
        });*/
  }
};
</script>

<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
