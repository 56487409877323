<template>
 
    
  <div class="modal fade" id="TransactionsDialog" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>

          <div class="modal-body">
         
            <table id="data-table-combine-billpayments" class="table table-striped table-bordered data-table-combinedo">
              <thead>
                <tr>
                  <th width="1%" data-orderable="false"></th>
                  <th class="text-wrap">Date</th>
                  <th class="text-nowrap">Source</th>
                  <th class="text-nowrap">Reference</th>
                  <th class="text-nowrap">Amount</th>
                  <th class="text-nowrap">Balance</th>
                  <!-- <th class="text-nowrap">Num Of Passangers</th>
                  <th class="text-nowrap">Total Fare Generated</th> -->
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item,index) in GETALLTRANS" :key="item.trans_id">
                  <td width="1%" class="">{{++index}}</td>
                      <td>{{item.trans_date | formatToLongDateTime}} </td>
                        <td>{{item.trans_source_name}} </td>
                      <td>{{item.reference}}</td>
                      <td>{{item.amount | newcurrencywithSymbol}}</td>
                      <td>{{item.balance | newcurrencywithSymbol}}</td>
                    </tr>
              </tbody>
            </table>
          
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- modal-dialog modal-dialog-centered -->
    </div>
  </div>
    <!-- /.modal -->

</template>

<script>


import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('commuter');

export default {
	 name: "TransactionsDialog",
	 props:['AddTaxiOperatorDropDownControl','AddTopSearchControl','AddTopNotificationControl','isAdminUser'],
  data: function() {
    return {
      GETALLTRANS: [],
      DataLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["USERPROFILE","SELECTEDTAXIASSOCIATION"]),
  },
  components: {
  },
  methods: {
    init: function ()
    {
      this.$emit('init')
    },
    GetAllTransactionsFn: function ()
    {
      var options;
      var context = $(window).data("contextdebitorder");
      context.$store.dispatch("commuter/GetAllTransactionsAction",{
        Position: 0,
        NumofRecords: 100,
      }).then((results)=>{
         if(results.Status){
           debugger;
         options = {
                    buttons: [
                    ],
                    responsive: true,
                    autoFill: false,
                    colReorder: false,
                    keys: true,
                    rowReorder: false,
                    select: false,
                    paging:   true,
                    ordering: true,
                    info:     true
                  };
         
          this.GETALLTRANS = results.Data;
         } else {
           this.$Notify.Error("TopUp Failed",results.Message);
         }
     }).catch((error)=>{
        this.$Notify.Error("TopUp Failed",error);
     }).finally(()=>{
          if(!this.DataLoaded){
            $(".data-table-combinedo").DataTable(options)
            this.DataLoaded = true;
          }
     })
    },
  },
   mounted: function() {

     $(window).data("contextdebitorder",this);

     $('#TransactionsDialog').on('show.bs.modal', function() {
        // do something when the modal is shown
          var context = $(window).data("contextdebitorder");
          debugger;
          context.GetAllTransactionsFn();

          analytics.page("Transactions Dialog","TransactionsDialog");
      });
     
    //  const contexttemp = this;
    //  this.$store.dispatch("TA/getLOGOURL", {
    //   id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    // }).then((result) => {

    //   //debugger;
      
    //   contexttemp.LOGO = result;
    // }).catch((err) => {
    // });
  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

