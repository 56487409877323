<template>

   <div class="modal fade" id="SharedWithSocials" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>

          <div class="modal-body p-4">
            <h6 class="mb-4">Share With Friends</h6>

            <div class="mb-3">
                <a class="btn btn-block btn-social btn-success w-100" href="https://bit.ly/38slyTg">
            <span class="fa fa-whatsapp"></span> Share On WhatsApp
          </a>
              </div>

              <div class="mb-3">
                <a class="btn btn-block btn-social btn-facebook w-100" href="https://www.facebook.com/bookaseatafrica/">
                <span class="fa fa-facebook"></span> Share On Facebook
              </a>
              </div>

               <div class="mb-3">
              <a class="btn btn-block btn-social btn-instagram w-100" href="https://www.instagram.com/bookaseatafrica/">
            <span class="fa fa-instagram"></span> Share On Instagram
          </a>
              </div>

               <div class="mb-3">
                <a class="btn btn-block btn-social btn-twitter w-100" href="https://twitter.com/bookaseatfrica">
            <span class="fa fa-twitter"></span> Share On Twitter
          </a>
              </div>

             

                 <p class="text-muted hide">Note: Post On Our Socials And Earn R1</p>
                  

            <!-- /.text-center -->
          </div>
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog modal-dialog-centered modal-sm -->
    </div>
    <!-- /.modal -->

</template>

<script>

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('auth');

export default {
	 name: "NextOfKinDetails",
	 props:['AddTaxiOperatorDropDownControl'],
  data: function() {
    return {
      FirstName: '',
      LastName: '',
      Mobile: '',
    };
  },
  computed: {
    ...mapGetters(["USERPROFILE","WALLETPROFILE"]),
    CanSubmit: function (){
      return this.FirstName != "" && this.LastName != "" && this.Mobile != ""
    },
  },
  components: {
  },
  methods: {
    ...mapActions([
      "RegisterCommuterAction",
      "RequestOTPAction",
      "GetProfile",
    ]),
    LoadData: function() {
      this.FirstName = this.WALLETPROFILE.nexofkeen_firstname;
      this.LastName = this.WALLETPROFILE.nexofkeen_surname;
      this.Mobile = this.WALLETPROFILE.nexofkeen_mobilenumber;
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    UpdateNextOfKinAction: function ()
    {



      this.$Notify
        .YesNo(false,true)
        .then((value) => {
          if (value) {
             var context = $(window).data("NextOfKinDetails");
              context.$wait.start("LoggingIn");

              analytics.track("Submit Update Next Of Kin", {
                FirstName:this.FirstName,
                Surname:this.LastName,
                MobileNumber:this.Mobile,
              });

              context.$store
              .dispatch("commuter/UpdateNextOfKinAction",{
              FirstName:this.FirstName,
              Surname:this.LastName,
              MobileNumber:this.Mobile,
              }).then((resutls) => {
              this.$wait.end("LoggingIn");
              if(resutls.Status){
                  this.$store.dispatch("auth/GetProfile");
                $('#NextOfKinDetails').modal('hide');
                this.$Notify.Success("Updated Successfully","");
              } else {
                this.$Notify.Error(resutls.Message, "");
              }
            }).catch(() => {
              this.$wait.end("LoggingIn");
              this.$Notify.Error("Something Went Wrong");
            })
          }
        });


    }
  },
   mounted: function() {

      $(window).data("SharedWithSocials",this);

     $('#SharedWithSocials').on('show.bs.modal', function() {
        // do something when the modal is shown
          // var context = $(window).data("NextOfKinDetails");
          
          // analytics.page("Next Of Kin Details","NextOfKinDetails");

          //  debugger;
          // context.LoadData();
        
      });


     
  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

