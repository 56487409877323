/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import Swal from 'sweetalert2';
export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue, options) {
    Vue.prototype.$Notify = class Notify {
      construct() {
      }

      static YesNo(cancelValue, yesValue) {
        return new Promise((resolve) => {

          Swal.fire({
            title: 'Are you sure?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4CD964',
            cancelButtonColor: '#FF3B30',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then((result) => {
            if (result.value) {
              resolve(yesValue);
            }
            else
            {
              resolve(cancelValue);
            }
          });

        });
      }

      static YesNoMore(title,text, cancelValue, yesValue) {
        return new Promise((resolve) => {

          Swal.fire({
            title,
            text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4CD964',
            cancelButtonColor: '#FF3B30',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then((result) => {
            if (result.value) {
              resolve(yesValue);
            }
            else
            {
              resolve(cancelValue);
            }
          });

        });
      }

      static Info(title, text, cancelValue, yesValue) {
        return new Promise((resolve) => {
          Swal.fire({
            title,
            text,
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: '#4CD964',
            cancelButtonColor: '#fff',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.value) {
              resolve(yesValue);
            }
            else
            {
              resolve(cancelValue);
            }
          });
        });
      }

      static Confirm(title, text, cancelValue, yesValue) {
        return new Promise((resolve) => {
          Swal.fire({
            title,
            text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4CD964',
            confirmButtonClass:'btn btn-success',
            confirmButtonText: 'Yes',
            cancelButtonClass:'btn btn-default',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.value) {
              resolve(yesValue);
            }
            else
            {
              resolve(cancelValue);
            }
          });
        });
      }

      static Success(title, text) {
        return new Promise((resolve) => {
        Swal.fire(
          title,
          text,
          'success'
        ).then((result) => {
          if (result.value) {
            resolve(result.value);
          }
          else
          {
            resolve();
          }
        });
      });
      }

      static Error(title, text) {
        return new Promise((resolve) => {
          Swal.fire(
            title,
            text,
            'error'
          ).then((result) => {
            if (result.value) {
              resolve(result.value);
            }
            else
            {
              resolve();
            }
          });
        });
      }

      static ErrorObject(_obj) {

        var errmessage;

        if(Array.isArray(_obj.data))
          errmessage  = error.data[0].message;
        else
          errmessage  = error.data.message;

        return new Promise((resolve) => {
          Swal.fire(
            errmessage,
            '',
            'error'
          ).then((result) => {
            if (result.value) {
              resolve(result.value);
            }
            else
            {
              resolve();
            }
          });
        });
      }

      static Toast(text,isSuccess,showConfirmButton) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton,
          timer: 3000
        });

        Toast.fire({
          type: isSuccess ? 'success': 'error',
          text,
        });
      }

      static ToastInfo(text,showConfirmButton) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton,
          timer: 3000
        });

        Toast.fire({
          type: 'info',
          text,
        });
      }

      static BlockScreen(title, message,block=false) {
        Swal.fire({
          title,
          text: message,
          //position: 'center-end',
          showCloseButton:false,
          backdrop:true,
          allowOutsideClick:!block,
          allowEscapeKey:!block,
          allowEnterKey:!block,
          showCancelButton: false,
          showConfirmButton:!block,
          type: 'error',
        });
      }

    };
  },
};
