<template>

 <div class="modal fade" id="userSignInModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>

          <div class="modal-body p-4">
              <h6 class="mb-4">Welcome back</h6>

              <div class="mb-3">
                <label for="userSignInEmail" class="form-label fs-sm fw-bold">Mobile Number</label>
                <input type="number" @keypress="isNumber($event)" required v-model="username" maxlength="10" class="form-control shadow-none" placeholder="Enter Mobile No" id="userSignInEmail" />
              </div>

             <div class="mb-3">
                <label for="userSignInEmail" class="form-label fs-sm fw-bold">Password(5 Digits)</label>
               <div class="input-group">
              <span class="input-group-text"><i class="fas fa-lock"></i></span>
              <input class="form-control" @keypress="isNumber($event)" type="password" id="password" name="password" minlength="5" maxlength="5" required v-model="password" placeholder="Enter New Password" >
              <span class="input-group-text">
                <i class="far fa-eye" id="togglePassword" 
              style="cursor: pointer"></i>
              </span>
            </div>
          </div>
              <div class="mb-3">
                <button 
                id="SignIn"
                type="submit" @click="SignIn" class="btn btn-primary w-100 text-uppercase fw-bold"
                :disabled="!CanSubmit"
                hidden
                >
                </button>

                  <button 
                data-sitekey="6LetUo8gAAAAAHSBpAM87p1t6ITqbUNiAZpUgRxO" 
                data-callback='onSubmitLogin' 
                data-action='submit'
                class="g-recaptcha btn btn-primary w-100 text-uppercase fw-bold"
                :disabled="!CanSubmit || this.$wait.is('LoggingIn')"
                >
                 <v-wait for="LoggingIn">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                     Sign In
                  </v-wait>
                  </button>
              </div>

              <div id="firebaseui-auth-container"></div>

            <div class="text-center text-secondary fs-sm">
              <p class="mb-0">Don't Have An Account
                <button 
                class="g-recaptcha btn btn-success w-100 text-uppercase fw-bold"
                data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#userSignupModal"
                >
                Create an account
                  </button>
              </p>
              <br/>
              
              
              <p class="mb-1">
                <a href="#" class="text-decoration-underline" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#userForgotModal">Forgot Password</a>
              </p>
            </div>
            <!-- /.text-center -->
          </div>
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- modal-dialog modal-dialog-centered -->
    </div>
    <!-- /.modal --> 

</template>

<script>


import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('auth');


export default {
	 name: "userSignInModal",
	 props:['AddTaxiOperatorDropDownControl','AddTopSearchControl','AddTopNotificationControl','isAdminUser'],
  data: function() {
    return {
      username: "",
      password: ""
    };
  },
  computed: {
    ...mapGetters(["USERPROFILE","SELECTEDTAXIASSOCIATION"]),
    CanSubmit: function (){
      return this.username != "" && this.password != "" && this.password.length == 5;
    }
  },
  components: {
  },
  methods: {
     ...mapActions([
      "SignInAction",
      "GetProfile",
    ]),
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    SignIn: function ()
    {
      if(this.username === "")
      {
        this.$Notify.Error("Please enter mobile number", "");
        return;
      }
      else if(this.password === "") {
        this.$toast.error("Please enter password");
        return;
      }

      analytics.track("Sign In Action", {username:this.username});

      this.$wait.start("LoggingIn");
      this.SignInAction({username:this.username,password:this.password}).then(response => {
      if(!response.Status)
      {
        this.$Notify.Error(response.Message, "");
      }
      else
      {

        this.GetProfile().then(response => {
          debugger;
          if(response.Status)
          {
            debugger;
            analytics.identify(response.Data.systemuserid, {
            name: response.Data.firstname + ' ' + response.Data.surname,
            email: response.Data.mobilenumber + "@tpay.africa",
            plan: "BookASeat",
            id: response.Data.idnumber,
            action: 'Login'
          });
          $('#userSignInModal').modal('hide');
          this.$Notify.Toast("Welcome Back, " + this.USERPROFILE.FirstName,true, false);
          } else {
            this.$Notify.Error("",response.Message);
            return;
          }
        });
        
       
      }
      }).catch(error => {
      }).finally(() => {
        this.$wait.end("LoggingIn");
      });

      //alert("SignIn")
    }
  },
   mounted: function() {


        function ActivatePassword(){
      const togglePassword = document.querySelector("#togglePassword");
      const password = document.querySelector("#password");

      togglePassword.addEventListener("click", function () {
        
        // toggle the type attribute
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        // toggle the eye icon
        this.classList.toggle('fa-eye');
        this.classList.toggle('fa-eye-slash');
      });
    }

   

    $('#userSignInModal').on('show.bs.modal', function() {
        // do something when the modal is shown
      
          analytics.page("user Sign In Modal","userSignInModal");

           ActivatePassword();
      });
     
    //  const contexttemp = this;
    //  this.$store.dispatch("TA/getLOGOURL", {
    //   id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    // }).then((result) => {

    //   //debugger;
      
    //   contexttemp.LOGO = result;
    // }).catch((err) => {
    // });
  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

