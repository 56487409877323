<template>

    <div class="modal fade" id="PreviewRentATaxiModals" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>

          <div class="modal-body">
                <div class="text-center text-uppercase fs-4 fw-bolder timeheader">Booking expires in <span class="w-bolder text-danger time" >05:00</span> minutes!</div>
                        
             <section id="listing-1" class="section py-2">
      <div class="bg bg-img position-absolute" data-bg-img=".img"></div>
      <!-- /.bg bg-img position-absolute -->

      <div class="container-xl position-relative">

        <div class="row properties g-4 mb-5">

          <div :key="option.id" v-for="option in ALLVEHICLETYPES" v-bind:value="option" class="col-12 col-sm-6 col-md-4 col-lg-3 d-flex align-items-stretch">
            <div class="card shadow-sm shadow-hover trans-base w-100 o-hover-all h-100">
              <div class="card-img-top overflow-hidden position-relative">
                <img :src="'/assets/img/' + option.imagename" class="w-100" alt="Image" />

                <div class="overlay position-absolute bg-dark o-25"></div>

                <div class="overlay position-absolute px-3 pt-3 pb-2 d-flex flex-column">
                  <div class="overlay-top d-flex flex-row">
                    <a href="javascript:void(0);" @click="PAY(option)" class="position-relative zi-20 badge text-dark bg-white o-50 o-100-hover trans-base">{{option.name}}</a>
                  </div>
                  <!-- /.overlay-top d-flex flex-row -->
                </div>
                <!-- /.overlay position-absolute px-3 pt-3 pb-2 d-flex flex-column -->
              </div>
              <!-- /.card-img-top overflow-hidden position-relative -->

              <div class="card-body pt-2">
                <p class="card-text mb-1 d-flex flex-row align-items-center w-100">
                  <a href="javascript:void(0);" @click="PAY(option)" class="stretched-link fs-6 fw-bold trans-base"><span class="o-50 me-2" style="text-decoration: line-through;">{{RENTALPRICESTRIKE(option) | newcurrencywithSymbol}}</span><span class="o-100-hover text-success">{{RENTALPRICE(option) | newcurrencywithSymbol}}</span></a>
                </p>

               
                <p class="row gx-0 gx-md-1 text-secondary mb-0 text-truncate d-block">
                  <span class="col-auto">Seats: <strong>{{option.seats}}</strong></span>
                  <span class="col-auto px-0 o-50">&bullet;</span>
                  <span class="col-auto">Driver: <strong>Yes</strong></span>
                </p>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col-12 col-md-3 d-flex align-items-stretch -->

        </div>
        <!-- /.row properties -->

       
      </div>
      <!-- /.container-xl -->
    </section>
          </div>
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- modal-dialog modal-dialog-centered -->
    </div>
    <!-- /.modal -->

</template>

<script>


import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('commuter');

export default {
	 name: "PreviewRentATaxiModals",
	 props:['AddTaxiOperatorDropDownControl'],
  data: function() {
    return {
      ALLVEHICLETYPES: [],
    };
  },
  computed: {
    ...mapGetters(["USERPROFILE","GETRENTALDETAILS"]),
    
  },
  components: {
  },
  methods: {
    DRIVERFEE: function(item){

      if(this.GETRENTALDETAILS.DaysTravel > 1)
      {
        return item.driverfee;
        //return 0;
      } else {
        return 0;
      }
    },
    PAY: function(item){

      analytics.track("Submit Preview Rent A Taxi Modals", item);

      this.$store.dispatch('commuter/SetRentalSelectedAction',item).then((results)=>{

        $('#PreviewRentATaxiModals').modal('hide');
        $('#RentATaxiModals').modal('show');
      }).catch((err)=>{

      }).finally(()=>{
      });

          
    },
    RENTALPRICE: function(item){

          item.SubTotal = ((this.GETRENTALDETAILS.Distance / 1000) * item.rateperkm) * this.GETRENTALDETAILS.DaysTravel + this.DRIVERFEE(item);

          return item.SubTotal;
    },
    RENTALPRICESTRIKE: function(item){

      var pes = ((((this.GETRENTALDETAILS.Distance / 1000) * item.rateperkm) * this.GETRENTALDETAILS.DaysTravel + this.DRIVERFEE(item)) * 20)/100;

      item.StrikePrice = ((this.GETRENTALDETAILS.Distance / 1000) * item.rateperkm) * this.GETRENTALDETAILS.DaysTravel + this.DRIVERFEE(item) + pes;

          return item.StrikePrice;
    },
    GetVehicleTypes: function ()
    {
      debugger;

      this.$store.dispatch('commuter/GetVehicleTypesAction').then((results)=>{
        if(results.Status){
          this.ALLVEHICLETYPES = results.Data;
        }
      }).catch((err)=>{

      }).finally(()=>{
      });
    },
  },
   mounted: function() {

     $(window).data("PreviewRentATaxiModals",this);

     $('#PreviewRentATaxiModals').on('show.bs.modal', function() {
        // do something when the modal is shown
        var context = $(window).data("PreviewRentATaxiModals");

        context.GetVehicleTypes();
        
        StartCount(0);

        analytics.page("Preview Rent A Taxi Modals","PreviewRentATaxiModals");
          
      });
     
    //  const contexttemp = this;
    //  this.$store.dispatch("TA/getLOGOURL", {
    //   id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    // }).then((result) => {

    //   //debugger;
      
    //   contexttemp.LOGO = result;
    // }).catch((err) => {
    // });
  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

