<template>

   <div class="modal fade" id="MissingDepartureTimeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>

          <div class="modal-body p-4">
            <h6 class="mb-4">Vote For Your Area</h6>


              <div class="row gx-2">
                <div class="col-12 col-sm-6">
                  <div class="mb-3">
                    <label for="userSignupFirstName" class="form-label fs-sm fw-bold">Full Name</label>
                    <input type="text" required v-model="fullname" placeholder="Enter First Name" class="form-control shadow-none"/>
                  </div>
                </div>
                <!-- /.col-12 -->

                 <div class="col-12 col-sm-6">
                  <div class="mb-3">
                    <label for="userSignupFirstName" class="form-label fs-sm fw-bold">Mobile No</label>
                    <input type="text" required v-model="mobileno" placeholder="Enter Mobile No" class="form-control shadow-none"/>
                  </div>
                </div>
                <!-- /.col-12 -->

                 <div class="col-12 col-sm-6">
                  <div class="mb-3">
                    <label for="userSignupLastName" class="form-label fs-sm fw-bold">Rank</label>
                    <input type="text" required v-model="rankname" placeholder="e.g Bree Rank" class="form-control shadow-none" />
                  </div>
                </div>
                <!-- /.col-12 -->

                <div class="col-12 col-sm-6">
                  <div class="mb-3">
                    <label for="userSignupLastName" class="form-label fs-sm fw-bold">Destination</label>
                    <input type="text" required v-model="destination" placeholder="e.g Soweto" class="form-control shadow-none" />
                  </div>
                </div>
                <!-- /.col-12 -->

                  <div class="col-12 col-auto">
                  <div class="mb-3">
                    <label for="userSignupLastName" class="form-label fs-sm fw-bold">Departure Time</label>
                    <input type="text" required v-model="departuretime" placeholder="e.g 8am" class="form-control shadow-none" />
                  </div>
                </div>
                <!-- /.col-12 -->

                 <div class="col-12 col-auto">
                  <div class="mb-3">
                    <label for="userSignupLastName" class="form-label fs-sm fw-bold">Feedback</label>
                    <textarea type="text" rows="4" cols="50" maxlength="200" required v-model="Feedback" placeholder="e.g Please Add The Rank In My Area" class="form-control shadow-none" id="rankname" />
                  </div>
                </div>
                <!-- /.col-12 -->
              </div>
              <!-- /.row -->

               <div class="mb-3">
                <button type="submit" @click="SUBMIT" class="btn btn-primary w-100 text-uppercase fw-bold"
                :disabled="!CanSubmit || this.$wait.is('LoggingIn')"
                >
                 <v-wait for="LoggingIn">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                     Submit
                  </v-wait>
                </button>
              </div>
                 <p class="text-muted hide">Note: Would you like us to add your nearest taxi rank, Please submit your details and we'll notify you once it has been addded. </p>
                  

            <!-- /.text-center -->
          </div>
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog modal-dialog-centered modal-sm -->
    </div>
    <!-- /.modal -->

</template>

<script>

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('auth');

export default {
	 name: "MissingDepartureTimeModal",
	 props:['AddTaxiOperatorDropDownControl'],
  data: function() {
    return {
      fullname: '',
      mobileno: '',
      rankname: '',
      destination:'',
      departuretime:'',
      Feedback:'',
    };
  },
  computed: {
    ...mapGetters(["USERPROFILE","WALLETPROFILE"]),
    CanSubmit: function (){
      return this.fullname != "" && this.mobileno != "" && this.Feedback != ""
    },
  },
  components: {
  },
  methods: {
    ...mapActions([
      "RegisterCommuterAction",
      "RequestOTPAction",
      "GetProfile",
    ]),
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    LoadData: function ()
    {
      this.fullname = this.WALLETPROFILE.firstname + ' ' + this.WALLETPROFILE.surname;
      this.mobileno = this.WALLETPROFILE.mobilenumber;
      
    },
    SUBMIT: function ()
    {

      this.$Notify
        .YesNo(false,true)
        .then((value) => {
          if (value) {
             var context = $(window).data("MissingDepartureTimeModal");
              context.$wait.start("LoggingIn");


              analytics.track("Feedback Submitted", {
                fullname: this.fullname,
                mobileno: this.mobileno,
                rankname: this.rankname,
                destination: this.destination,
                departuretime: this.departuretime,
                Feedback: this.Feedback,
              });

              $('#MissingDepartureTimeModal').modal('hide');

              this.$Notify.Success("Submitted Successfully","");


              context.$wait.end("LoggingIn");
                
          }
        });


    }
  },
   mounted: function() {

      $(window).data("MissingDepartureTimeModal",this);
      

          function ActivatePassword(){
      const togglePassword = document.querySelector("#togglePasswordedit");
      const password = document.querySelector("#passwordsigninedit");

      togglePassword.addEventListener("click", function () {
        
        // toggle the type attribute
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        // toggle the eye icon
        this.classList.toggle('fa-eye');
        this.classList.toggle('fa-eye-slash');
      });
    }

    ActivatePassword();

    $(window).data("MissingDepartureTimeModal",this);

     $('#MissingDepartureTimeModal').on('show.bs.modal', function() {
        // do something when the modal is shown
          var context = $(window).data("MissingDepartureTimeModal");
          
          debugger;
          //context.GetAllBookings();

           analytics.page("Missing Departure Time Modal","MissingDepartureTimeModal");

            context.LoadData()

      });

     
      
     
  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

