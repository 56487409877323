/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import moment from 'moment';
export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue, options) {
    Vue.prototype.$Toolkit = class Toolkit {
      construct() {
      }

      static ConvertToServerDate(_date) {
        if(_date)
        return moment(String(_date)).format('YYYY-MM-DDThh:mm:ss');
        else
        return _date;
      }
      static renameKeys = (keysMap, obj) => {
        
        return Object
            .keys(obj)
            .reduce((acc, key) => {
         
                const renamedObject = {
                    [keysMap[key] || key]: obj[key]
                };
           
                return {
                    ...acc,
                    ...renamedObject
                }
            }, {});
    };

      static ConvertToDate(_date,_dateFormat) {
        if(_date)
        return moment(String(_date)).format(_dateFormat);
        else
        return _date;
      }
    }
  }
};
