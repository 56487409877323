<template>

   <div class="modal fade" id="NextOfKinDetails" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>

          <div class="modal-body p-4">
            <h6 class="mb-4">Emergency Details(e.g Wife, Brother)</h6>


             <div class="row gx-2">
                <div class="col-12 col-sm-6">
                  <div class="mb-3">
                    <label for="userSignupFirstName" class="form-label fs-sm fw-bold">First Name</label>
                    <input type="text" required v-model="FirstName" placeholder="Enter First Name" class="form-control shadow-none" id="userSignupFirstName" />
                  </div>
                </div>
                <!-- /.col-12 -->

                <div class="col-12 col-sm-6">
                  <div class="mb-3">
                    <label for="userSignupLastName" class="form-label fs-sm fw-bold">Last Name</label>
                    <input type="text" required v-model="LastName" placeholder="Enter Last Name" class="form-control shadow-none" id="userSignupLastName" />
                  </div>
                </div>
                <!-- /.col-12 -->
              </div>
              <!-- /.row -->

              <div class="mb-3">
                <label for="userSignupEmail" class="form-label fs-sm fw-bold">Mobile No</label>
                <input type="number" @keypress="isNumber($event)" minlength="5" maxlength="5" required v-model="Mobile" placeholder="Enter Mobile No" class="form-control shadow-none" id="userSignupEmail" />
              </div>

               <div class="mb-3">
                <button type="submit" @click="UpdateNextOfKinAction" class="btn btn-primary w-100 text-uppercase fw-bold"
                :disabled="!CanSubmit || this.$wait.is('LoggingIn')"
                >
                 <v-wait for="LoggingIn">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                     Submit
                  </v-wait>
                </button>
              </div>
                 <p class="text-muted">Note: Please provide us with details of your next of keen to be used in case of emergency </p>
                  

            <!-- /.text-center -->
          </div>
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog modal-dialog-centered modal-sm -->
    </div>
    <!-- /.modal -->

</template>

<script>

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('auth');

export default {
	 name: "NextOfKinDetails",
	 props:['AddTaxiOperatorDropDownControl'],
  data: function() {
    return {
      FirstName: '',
      LastName: '',
      Mobile: '',
    };
  },
  computed: {
    ...mapGetters(["USERPROFILE","WALLETPROFILE"]),
    CanSubmit: function (){
      return this.FirstName != "" && this.LastName != "" && this.Mobile != ""
    },
  },
  components: {
  },
  methods: {
    ...mapActions([
      "RegisterCommuterAction",
      "RequestOTPAction",
      "GetProfile",
    ]),
    LoadData: function() {
      this.FirstName = this.WALLETPROFILE.nexofkeen_firstname;
      this.LastName = this.WALLETPROFILE.nexofkeen_surname;
      this.Mobile = this.WALLETPROFILE.nexofkeen_mobilenumber;
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    UpdateNextOfKinAction: function ()
    {



      this.$Notify
        .YesNo(false,true)
        .then((value) => {
          if (value) {
             var context = $(window).data("NextOfKinDetails");
              context.$wait.start("LoggingIn");

              analytics.track("Submit Update Next Of Kin", {
                FirstName:this.FirstName,
                Surname:this.LastName,
                MobileNumber:this.Mobile,
              });

              context.$store
              .dispatch("commuter/UpdateNextOfKinAction",{
              FirstName:this.FirstName,
              Surname:this.LastName,
              MobileNumber:this.Mobile,
              }).then((resutls) => {
              this.$wait.end("LoggingIn");
              if(resutls.Status){
                  this.$store.dispatch("auth/GetProfile");
                $('#NextOfKinDetails').modal('hide');
                this.$Notify.Success("Updated Successfully","");
              } else {
                this.$Notify.Error(resutls.Message, "");
              }
            }).catch(() => {
              this.$wait.end("LoggingIn");
              this.$Notify.Error("Something Went Wrong");
            })
          }
        });


    }
  },
   mounted: function() {

      $(window).data("NextOfKinDetails",this);

     $('#NextOfKinDetails').on('show.bs.modal', function() {
        // do something when the modal is shown
          var context = $(window).data("NextOfKinDetails");
          
          analytics.page("Next Of Kin Details","NextOfKinDetails");

           debugger;
          context.LoadData();
        
      });


     
  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

