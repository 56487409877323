<template>

   <div class="modal fade" id="userSignupModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>

          <div class="modal-body p-4">
            <h6 class="mb-4">Create an Account</h6>


              <div class="row gx-2">
                <div class="col-12 col-sm-6">
                  <div class="mb-3">
                    <label for="userSignupFirstName" class="form-label fs-sm fw-bold">First Name</label>
                    <input type="text" required v-model="FirstName" placeholder="Enter First Name" class="form-control shadow-none" id="userSignupFirstName" />
                  </div>
                </div>
                <!-- /.col-12 -->

                <div class="col-12 col-sm-6">
                  <div class="mb-3">
                    <label for="userSignupLastName" class="form-label fs-sm fw-bold">Last Name</label>
                    <input type="text" required v-model="LastName" placeholder="Enter Last Name" class="form-control shadow-none" id="userSignupLastName" />
                  </div>
                </div>
                <!-- /.col-12 -->
              </div>
              <!-- /.row -->

              <div class="mb-3">
                <label for="userSignupEmail" class="form-label fs-sm fw-bold">Mobile No</label>
                <input type="number" 
                :disabled="VerifyOTP"
                @keypress="isNumber($event)" minlength="5" maxlength="5" required v-model="Mobile" placeholder="Enter Mobile No" class="form-control shadow-none" id="userSignupEmail" />
              </div>

            <div class="mb-3">
                <label for="userSignInEmail" class="form-label fs-sm fw-bold">New Password(5 Digits)</label>
               <div class="input-group">
              <span class="input-group-text"><i class="fas fa-lock"></i></span>
              <input class="form-control" @keypress="isNumber($event)" type="password" id="passwordsignin" name="password" minlength="5" maxlength="5" required v-model="Password" placeholder="Enter New Password" >
              <span class="input-group-text">
                <i class="far fa-eye" id="togglePassword" 
              style="cursor: pointer"></i>
              </span>
            </div>
          </div>

              <div class="mb-3" v-if="VerifyOTP">
                <label for="userSignInPassword" class="form-label fs-sm fw-bold">Enter OTP Number</label>
               <div class="input-group mb-3" >
              <button class="btn btn-outline-secondary" type="button" id="button-addon1"
              :disabled="!CanSubmitBeforeOTP || $wait.is('LoggingIn')"
              @click="VerifyOTPClick"
              ><v-wait for="LoggingIn">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                     Resend
                  </v-wait></button>
              <input type="number" @keypress="isNumber($event)" :class="MATCHED ? 'alert-success' : 'alert-danger'" ref="OTP" minlength="4" maxlength="4" v-model="OTP" class="form-control shadow-none" placeholder="Enter OTP Number" id="userSignInPassword" />
              </div>
              </div>

               <div class="mb-3" v-if="VerifyOTP">
                <button type="submit" @click="RegisterCommuter" class="btn btn-primary w-100 text-uppercase fw-bold"
                :disabled="!CanSubmit || $wait.is('LoggingIn')"
                >
                 <v-wait for="LoggingIn">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                     Submit
                  </v-wait>
                </button>
              </div>

              <div class="mb-3" v-if="!VerifyOTP">
                <button 
                id="VerifyOTPClick"
                type="submit" @click="VerifyOTPClick" class="btn btn-primary w-100 text-uppercase fw-bold"
                :disabled="!CanSubmitBeforeOTP"
                hidden
                >
                </button>

                <button 
                 data-sitekey="6LetUo8gAAAAAHSBpAM87p1t6ITqbUNiAZpUgRxO" 
                data-callback='onSubmitSignUp' 
                data-action='submit'
                class="g-recaptcha btn btn-primary w-100 text-uppercase fw-bold"
                :disabled="!CanSubmitBeforeOTP || $wait.is('LoggingIn')"
                >
                 <v-wait for="LoggingIn">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                     Sign Up For Free
                  </v-wait>
                </button>
              </div>

            <div class="text-center text-secondary fs-sm">
              <p class="mb-0">Already have an account? <a href="#" class="text-decoration-underline" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#userSignInModal">Sign In</a></p>
            </div>
            <!-- /.text-center -->
          </div>
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog modal-dialog-centered modal-sm -->
    </div>
    <!-- /.modal -->

</template>

<script>

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('auth');

export default {
	 name: "userSignupModal",
	 props:['AddTaxiOperatorDropDownControl'],
  data: function() {
    return {
      FirstName: '',
      LastName: '',
      Mobile: '',
      Password: '',
      OTP: '',
      VerifyOTP: false,
      MatchOTP: '',
    };
  },
  computed: {
    ...mapGetters(["USERPROFILE","SELECTEDTAXIASSOCIATION"]),
    CanSubmit: function (){
      return this.FirstName != "" && this.LastName != "" && this.Mobile != "" && this.Password != "" && this.Password.length == 5 &&
       this.OTP.length === 4 && this.MatchOTP === this.OTP;
    },
    CanSubmitBeforeOTP: function (){
      return this.FirstName != "" && this.LastName != "" && this.Mobile != "" && this.Password != "" && this.Password.length == 5;
    },
     MATCHED: function (){
      return this.OTP.length === 4 && this.MatchOTP === this.OTP;
    },
  },
  components: {
  },
  methods: {
    ...mapActions([
      "RegisterCommuterAction",
      "RequestOTPAction",
      "GetProfile",
    ]),
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    VerifyOTPClick: function ()
    {
      var context = $(window).data("userSignupModal");

      analytics.track("VerifyOTPClick", {
              mobilenumber: this.Mobile,
      });

        this.$Notify
        .Confirm("OTP Will be Sent You","",false,true)
        .then((value) => {
          if (value) {
             //this.$refs.OTP.focus();

              context.$wait.start("LoggingIn");
            
            context.$store
          .dispatch("auth/RequestOTPAction",{
              mobilenumber: this.Mobile,
            }).then((resutls) => {
              context.$wait.end("LoggingIn");
              if(resutls.Status){
              context.VerifyOTP = true;
              context.MatchOTP = resutls.Data.otpnumber;
              } else {
              context.$Notify.Error("Please verify your mobile number", "");
              }
            }).catch(() => {
              this.$Notify.Error("Something Went Wrong");
            });

          }
        });

    },
    RegisterCommuter: function ()
    {

       var context = $(window).data("userSignupModal");

       analytics.track("Register Commuter", {
              firstname:this.FirstName,
              surname:this.LastName,
              mobilenumber:this.Mobile,
              password:this.Password,
        });


      this.$Notify
        .YesNo(false,true)
        .then((value) => {
          if (value) {
            context.$wait.start("LoggingIn");

              context.$store
              .dispatch("auth/RegisterCommuterAction",{
              firstname:this.FirstName,
              surname:this.LastName,
              mobilenumber:this.Mobile,
              password:this.Password,
              }).then((resutls) => {
              context.$wait.end("LoggingIn");
              if(resutls.Status){
                  context.$store
                .dispatch("auth/GetProfile");
                $('#userSignupModal').modal('hide');
                context.$Notify.Toast("Welcome, " + context.FirstName,true, false);
              } else {
                context.$Notify.Error(resutls.Message, "");
              }
            }).catch(() => {
              context.$wait.end("LoggingIn");
              context.$Notify.Error("Something Went Wrong");
            });
          }
        });


    }
  },
   mounted: function() {

      $(window).data("userSignupModal",this);

          function ActivatePassword(){
      const togglePassword = document.querySelector("#togglePassword");
      const password = document.querySelector("#passwordsignin");

      togglePassword.addEventListener("click", function () {
        
        // toggle the type attribute
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        // toggle the eye icon
        this.classList.toggle('fa-eye');
        this.classList.toggle('fa-eye-slash');
      });
    }

    $('#userSignupModal').on('show.bs.modal', function() {
        // do something when the modal is shown
          var context = $(window).data("userSignupModal");

          analytics.page("user Sign In Modal","userSignupModal");

           ActivatePassword();
      });
     
    //  const contexttemp = this;
    //  this.$store.dispatch("TA/getLOGOURL", {
    //   id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    // }).then((result) => {

    //   //debugger;
      
    //   contexttemp.LOGO = result;
    // }).catch((err) => {
    // });
  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

